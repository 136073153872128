import shuffleLogo from '../assets/svg/Shuffle-Logo.svg'
import yourLogo from '../assets/images/your-logo.png'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectBrandingLogo } from '../pages/invitation/reducer'

interface IProps {}

const Header: React.FC<IProps> = (props) => {
  const brandingLogo = useSelector(selectBrandingLogo)

  return (
    <div id="header-wrapper" className="login-header">
      <div id="header" className="main-nav self-reg-nav">
        <div className="logo-holder row">
          <a id="shuffle-logo" href="/home">
            <img src={shuffleLogo} alt="Shuffle" />
          </a>
          <a id="your-logo" href="/home">
            {brandingLogo && <img src={brandingLogo.url} alt="Your Logo" />}
            {!brandingLogo && <img src={yourLogo} alt="Your Logo" />}
          </a>
        </div>
      </div>
    </div>
  )
}
export default Header
