import { LoadingActionTypes, RESET_LOADING, SET_LOADING } from './types'

export function setLoading(): LoadingActionTypes {
  return {
    type: SET_LOADING,
  }
}

export function resetLoading(): LoadingActionTypes {
  return {
    type: RESET_LOADING,
  }
}
