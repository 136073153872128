import { RootState } from '../../index'

export const SET_ERROR = 'SET_ERROR'
export const RESET_ERROR = 'RESET_ERROR'

export interface ErrorState {
  error: string | null
}

interface SetErrorAction {
  type: typeof SET_ERROR
  payload: {
    error: string
  }
}

interface ResetErrorAction {
  type: typeof RESET_ERROR
}

export type ErrorActionTypes = SetErrorAction | ResetErrorAction

export const selectError = (state: RootState) => (state.errorReducer.error ? state.errorReducer.error : null)
