import {
  AggregateActionTypes,
  AggregateTypes,
  DELETE_AGGREGATE_FAILURE,
  DELETE_AGGREGATE_REQUEST,
  DELETE_AGGREGATE_SUCCESS,
  GET_AGGREGATE_FAILURE,
  GET_AGGREGATE_REQUEST,
  GET_AGGREGATE_SUCCESS,
  INSERT_AGGREGATE_FAILURE,
  INSERT_AGGREGATE_REQUEST,
  INSERT_AGGREGATE_SUCCESS,
  LIST_AGGREGATES_FAILURE,
  LIST_AGGREGATES_ID_NAME_FAILURE,
  LIST_AGGREGATES_ID_NAME_NOT_MODIFIED,
  LIST_AGGREGATES_ID_NAME_REQUEST,
  LIST_AGGREGATES_ID_NAME_SUCCESS,
  LIST_AGGREGATES_NOT_MODIFIED,
  LIST_AGGREGATES_REQUEST,
  LIST_AGGREGATES_SUCCESS,
  RESET_AGGREGATE,
  UPDATE_AGGREGATE_FAILURE,
  UPDATE_AGGREGATE_REQUEST,
  UPDATE_AGGREGATE_SUCCESS,
  UPDATE_LIFECYCLE_FAILURE,
  UPDATE_LIFECYCLE_REQUEST,
  UPDATE_LIFECYCLE_SUCCESS,
} from './aggregate.types'
import { CachedAggregates, CachedIdNames, IdOnly, LifecycleOnly } from './types'

export interface IdentifiableAggregate {
  id?: string | null
}

export function listAggregatesRequest<T>(aggregateType: AggregateTypes): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_REQUEST,
    payload: {
      aggregateType: aggregateType,
    },
  }
}

export function listAggregatesSuccess<T>(
  aggregateType: AggregateTypes,
  aggregates: CachedAggregates<T>,
): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_SUCCESS,
    payload: {
      aggregateType: aggregateType,
      aggregates: aggregates,
    },
  }
}

export function listAggregatesNotModified<T>(aggregateType: AggregateTypes): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_NOT_MODIFIED,
    payload: {
      aggregateType: aggregateType,
    },
  }
}

export function listAggregatesFailure<T>(aggregateType: AggregateTypes, error: string): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_FAILURE,
    payload: {
      aggregateType: aggregateType,
      error: error,
    },
  }
}

export function getAggregateRequest<T>(aggregateType: AggregateTypes, id: string | null): AggregateActionTypes<T> {
  return {
    type: GET_AGGREGATE_REQUEST,
    payload: {
      aggregateType: aggregateType,
      id: id,
    },
  }
}

export function getAggregateSuccess<T>(aggregateType: AggregateTypes, aggregate: T): AggregateActionTypes<T> {
  return {
    type: GET_AGGREGATE_SUCCESS,
    payload: {
      aggregateType: aggregateType,
      aggregate: aggregate,
    },
  }
}

export function getAggregateFailure<T>(aggregateType: AggregateTypes, error: string): AggregateActionTypes<T> {
  return {
    type: GET_AGGREGATE_FAILURE,
    payload: {
      aggregateType: aggregateType,
      error: error,
    },
  }
}

export function updateAggregateRequest<T>(aggregateType: AggregateTypes, aggregate: T): AggregateActionTypes<T> {
  return {
    type: UPDATE_AGGREGATE_REQUEST,
    payload: {
      aggregateType: aggregateType,
      aggregate: aggregate,
    },
  }
}

export function updateAggregateSuccess<T>(aggregateType: AggregateTypes, aggregate: T): AggregateActionTypes<T> {
  return {
    type: UPDATE_AGGREGATE_SUCCESS,
    payload: {
      aggregateType: aggregateType,
      aggregate: aggregate,
    },
  }
}

export function updateAggregateFailure<T>(aggregateType: AggregateTypes, error: string): AggregateActionTypes<T> {
  return {
    type: UPDATE_AGGREGATE_FAILURE,
    payload: {
      aggregateType: aggregateType,
      error: error,
    },
  }
}

export function insertAggregateRequest<T>(aggregateType: AggregateTypes, aggregate: T): AggregateActionTypes<T> {
  return {
    type: INSERT_AGGREGATE_REQUEST,
    payload: {
      aggregateType: aggregateType,
      aggregate: aggregate,
    },
  }
}

export function insertAggregateSuccess<T>(aggregateType: AggregateTypes, idOnly: IdOnly): AggregateActionTypes<T> {
  return {
    type: INSERT_AGGREGATE_SUCCESS,
    payload: {
      aggregateType: aggregateType,
      idOnly: idOnly,
    },
  }
}

export function insertAggregateFailure<T>(aggregateType: AggregateTypes, error: string): AggregateActionTypes<T> {
  return {
    type: INSERT_AGGREGATE_FAILURE,
    payload: {
      aggregateType: aggregateType,
      error: error,
    },
  }
}

export function deleteAggregateRequest<T>(aggregateType: AggregateTypes, id: string): AggregateActionTypes<T> {
  return {
    type: DELETE_AGGREGATE_REQUEST,
    payload: {
      aggregateType: aggregateType,
      id: id,
    },
  }
}

export function deleteAggregateSuccess<T>(aggregateType: AggregateTypes): AggregateActionTypes<T> {
  return {
    type: DELETE_AGGREGATE_SUCCESS,
    payload: {
      aggregateType: aggregateType,
    },
  }
}

export function deleteAggregateFailure<T>(aggregateType: AggregateTypes, error: string): AggregateActionTypes<T> {
  return {
    type: DELETE_AGGREGATE_FAILURE,
    payload: {
      aggregateType: aggregateType,
      error: error,
    },
  }
}

export function resetAggregate<T>(aggregateType: AggregateTypes): AggregateActionTypes<T> {
  return {
    type: RESET_AGGREGATE,
    payload: {
      aggregateType: aggregateType,
    },
  }
}

export function listAggregatesIdNamesRequest<T>(aggregateType: AggregateTypes): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_ID_NAME_REQUEST,
    payload: {
      aggregateType: aggregateType,
    },
  }
}

export function listAggregatesIdNamesSuccess<T>(
  aggregateType: AggregateTypes,
  idNames: CachedIdNames,
): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_ID_NAME_SUCCESS,
    payload: {
      aggregateType: aggregateType,
      idNames: idNames,
    },
  }
}

export function listAggregatesIdNamesNotModified<T>(aggregateType: AggregateTypes): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_ID_NAME_NOT_MODIFIED,
    payload: {
      aggregateType: aggregateType,
    },
  }
}

export function listAggregatesIdNamesFailure<T>(aggregateType: AggregateTypes, error: string): AggregateActionTypes<T> {
  return {
    type: LIST_AGGREGATES_ID_NAME_FAILURE,
    payload: {
      aggregateType: aggregateType,
      error: error,
    },
  }
}

export function updateLifeCycleRequest<T>(
  aggregateType: AggregateTypes,
  lifeCycleOnly: LifecycleOnly,
): AggregateActionTypes<T> {
  return {
    type: UPDATE_LIFECYCLE_REQUEST,
    payload: {
      aggregateType: aggregateType,
      lifeCycleOnly: lifeCycleOnly,
    },
  }
}

export function updateLifeCycleSuccess<T>(aggregateType: AggregateTypes): AggregateActionTypes<T> {
  return {
    type: UPDATE_LIFECYCLE_SUCCESS,
    payload: {
      aggregateType: aggregateType,
    },
  }
}

export function updateLifeCycleFailure<T>(aggregateType: AggregateTypes, error: string): AggregateActionTypes<T> {
  return {
    type: UPDATE_LIFECYCLE_FAILURE,
    payload: {
      aggregateType: aggregateType,
      error: error,
    },
  }
}
