import Container from '@mui/material/Container'
import React from 'react'
import Header from './header'
import Footer from './footer'
import { Outlet } from 'react-router-dom'
import DisplayNotification from '../store/common/notifications/DisplayNotification'
import { useSelector } from 'react-redux'
import { selectBrandingColor } from '../pages/invitation/reducer'
import Seo from '../components/seo'

interface IProps {
  children?: React.ReactNode
}

const MainLayout: React.FC<IProps> = (props) => {
  const brandingColor: string | null | undefined = useSelector(selectBrandingColor)
  const { children } = props
  return (
    <>
      <Seo />
      <Container className="selfRegBG" style={{ backgroundColor: brandingColor ? brandingColor : '#dce5f7' }}>
        <Header />
        <DisplayNotification />
        <div style={{ minHeight: 'calc(100vh - 77px)' }}>
          <Outlet />
          {children}
        </div>
        <Footer />
      </Container>
    </>
  )
}
export default MainLayout
