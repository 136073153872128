import React from 'react'
import { Container } from '@mui/material'
import Grid from '@mui/material/Grid'
import emailIcon from '../../../assets/svg/ws-b2b-icon-email.svg'
import { Support } from '../../invitation/types'
import { useSelector } from 'react-redux'
import { selectSupportConfig } from '../../invitation/reducer'

interface IProps {
  onClickResend: (showNotification?: boolean | undefined) => void
}
const EmailResendPage: React.FC<IProps> = (props) => {
  const { onClickResend } = props

  const supportConfig: Support | null = useSelector(selectSupportConfig)

  return (
    <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} className="justified-content">
          <img className="self-reg-icon self-reg-icon-email" src={emailIcon} alt="Email" />
          <h2 className="self-reg-h2 centered-h2">This link has expired.</h2>
          <p className="self-reg-sub-text">Please check your email. We’ve sent you an email containing a new link.</p>
        </Grid>
        <Grid item xs={12}>
          <p className="self-reg-email-text">
            The email may take up to five minutes to arrive and could be in your spam or junk folders. Please contact
            your company administrator on&nbsp;
            <strong>
              {supportConfig && supportConfig.email ? (
                <a href={'mailto:' + supportConfig.email}>{supportConfig.email}</a>
              ) : (
                <a href="mailto:shuffle@company.com.au">shuffle@company.com.au</a>
              )}
            </strong>
            &nbsp;if you need help.
          </p>

          <p>
            <div className="resend-link" onClick={() => onClickResend(true)}>
              Send the email again
            </div>
          </p>
        </Grid>
      </Grid>
    </Container>
  )
}

export default EmailResendPage
