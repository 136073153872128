import {
  APP_CONFIG,
  MemberApiConfig,
  AppConfigActionTypes,
  AppConfigState,
  TENANT_CONFIG_FAILURE,
  TENANT_CONFIG_REQUEST,
  TENANT_CONFIG_SUCCESS,
  TenantConfig,
  UPDATE_APP_CONFIG,
  PaymentApiConfig,
} from './types'
import { RootState } from '../../store'

const initialState: AppConfigState = {
  configured: false,
  loading: false,
  error: null,
  tenantConfig: null,
  memberApiConfig: null,
  paymentApiConfig: null,
}

export function appConfigReducer(state: AppConfigState = initialState, action: AppConfigActionTypes): AppConfigState {
  function buildShuffleConfig(tenantConfig: TenantConfig): MemberApiConfig | null {
    if (tenantConfig.config.type === APP_CONFIG) {
      return {
        shuffleApiUrl: tenantConfig.config.apiPath,
      }
    } else {
      return null
    }
  }

  function buildPaymentConfig(tenantConfig: TenantConfig): PaymentApiConfig | null {
    if (tenantConfig.config.type === APP_CONFIG) {
      return {
        paymentApiUrl: tenantConfig.config.paymentApiPath ? tenantConfig.config.paymentApiPath : 'payment-api',
      }
    } else {
      return null
    }
  }

  function buildShuffleConfigUrl(url: string): MemberApiConfig | null {
    return {
      shuffleApiUrl: url,
    }
  }

  function buildPaymentConfigUrl(url: string): PaymentApiConfig | null {
    return {
      paymentApiUrl: url,
    }
  }

  switch (action.type) {
    case TENANT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case TENANT_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantConfig: action.payload.data,
        configured: true,
        memberApiConfig: buildShuffleConfig(action.payload.data),
        paymentApiConfig: buildPaymentConfig(action.payload.data),
      }
    case TENANT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case UPDATE_APP_CONFIG:
      return {
        ...state,
        loading: false,
        configured: true,
        memberApiConfig: buildShuffleConfigUrl(action.payload.url),
        paymentApiConfig: buildPaymentConfigUrl('payment-api'),
      }
    default:
      return state
  }
}

export const selectShuffleApiConfig = (state: RootState) => state.appConfigReducer.memberApiConfig
export const selectTenantConfig = (state: RootState) => state.appConfigReducer.tenantConfig
export const selectCurrentOperatorId = (state: RootState) => state.appConfigReducer.tenantConfig?.config.operatorId
export const selectCurrentApiKey = (state: RootState) => state.appConfigReducer.tenantConfig?.tenant.apiKey
export const selectCurrentTenantId = (state: RootState) => state.appConfigReducer.tenantConfig?.tenant.id
export const selectShuffleUrl = (state: RootState) => state.appConfigReducer.memberApiConfig?.shuffleApiUrl
export const selectPaymentUrl = (state: RootState) => {
  if (state.appConfigReducer.paymentApiConfig && state.appConfigReducer.paymentApiConfig.paymentApiUrl) {
    return state.appConfigReducer.paymentApiConfig?.paymentApiUrl
  } else {
    return 'payment-api'
  }
}
