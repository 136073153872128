import { RootState } from '../../index'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export interface NotificationState {
  type: NotificationType | null
  messages: string[] | null
}

export enum NotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

interface SetNotification {
  type: typeof SET_NOTIFICATION
  payload: {
    type: NotificationType
    messages: string[]
  }
}

interface ClearNotification {
  type: typeof CLEAR_NOTIFICATION
}

export type NotificationActionTypes = SetNotification | ClearNotification

export const selectGlobalError = (state: RootState) => {
  if (state.notificationsReducer.type === NotificationType.ERROR) {
    return state.notificationsReducer.messages
  }
  return null
}
