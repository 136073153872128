import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import {
  CARD_CVV_REGULAR_EXPRESSION,
  CARD_NUMBER_REGULAR_EXPRESSION,
  CardDetails,
  CardDetailsError,
  CreditCardType,
  GatewayTokenizedCardData,
  getCardType,
  MASK_CARD_NUMBER_REGEX,
  MASK_NON_DIGIT_REGEX,
  MASKED_CARD_NUMBER_REGEX,
  MM_YY_DATE_FORMAT_REGULAR_EXPRESSION,
  PAYMENT_SESSION_RESPONSE_API_RESOURCE_PATH,
  TokenizeInfo,
  TokenizeRequest,
} from '../../pages/credit-card/types'
import creditCardType from 'credit-card-type'
import makeStyles from '@mui/styles/makeStyles'
import { resetLoading, setLoading } from '../../store/common/loading/action'
import axios from 'axios'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentOperatorId, selectPaymentUrl } from '../../config/app/reducers'
import { selectLoading } from '../../store/common/loading/types'
import { CircularProgress } from '@mui/material'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
}))
interface IProps {
  tokenizeInfo?: TokenizeInfo
  sessionId?: string | null
  redirectUrl?: string | null
}

const DefaultAddCard: React.FC<IProps> = (props) => {
  const { sessionId, redirectUrl } = props
  const classes = useStyles()

  const dispatch = useDispatch()
  const loading = useSelector(selectLoading)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const paymentApiUrl = useSelector(selectPaymentUrl)

  const [isValidCardDetails, setIsValidCardDetails] = useState<boolean>(false)

  const [cardDetailsError, setCardDetailsError] = useState<CardDetailsError>({
    cardNumber: false,
    cardExpiry: false,
    cardCvv: false,
  })

  const [cardDetails, setCardDetails] = useState<CardDetails>({
    cardType: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvv: '',
  })

  useEffect(() => {
    if (cardDetails.cardNumber && cardDetails.cardExpiry && cardDetails.cardCvv) {
      if (cardDetailsError.cardNumber || cardDetailsError.cardExpiry || cardDetailsError.cardCvv) {
        setIsValidCardDetails(false)
      } else {
        setIsValidCardDetails(true)
      }
    } else {
      setIsValidCardDetails(false)
    }
    // eslint-disable-next-line
  }, [cardDetails])

  useEffect(() => {
    if (cardDetailsError.cardNumber || cardDetailsError.cardExpiry || cardDetailsError.cardCvv) {
      setIsValidCardDetails(false)
    }
    // eslint-disable-next-line
  }, [cardDetailsError])

  const onChangeField = (event: any) => {
    let { name, value } = event.target
    if (name === 'cardNumber') {
      let cardNumber = value
      cardNumber = cardNumber.replace(MASK_NON_DIGIT_REGEX, '')
      cardNumber = cardNumber.slice(0, 16)
      cardNumber = cardNumber.replace(MASK_CARD_NUMBER_REGEX, '$1 ')
      setCardDetails({
        ...cardDetails,
        [name]: cardNumber,
      })
    } else if (name === 'cardExpiry') {
      let cardExpiry = value.replace(MASK_NON_DIGIT_REGEX, '')
      cardExpiry = cardExpiry.slice(0, 4)
      if (cardExpiry.length > 2) {
        cardExpiry = cardExpiry.slice(0, 2) + '/' + cardExpiry.slice(2)
      }
      setCardDetails({
        ...cardDetails,
        [name]: cardExpiry,
      })
    } else if (name === 'cardCvv') {
      let cardCvv = value
      cardCvv = cardCvv.replace(MASK_NON_DIGIT_REGEX, '')
      cardCvv = cardCvv.slice(0, 3)
      setCardDetails({
        ...cardDetails,
        [name]: cardCvv,
      })
    } else {
      setCardDetails({
        ...cardDetails,
        [name]: value,
      })
    }
    setCardDetailsError({
      ...cardDetailsError,
      [name]: false,
    })
  }

  const validateField = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const { name, value } = event.target
    if (name === 'cardNumber') {
      if (!value.match(CARD_NUMBER_REGULAR_EXPRESSION)) {
        setCardDetailsError({
          ...cardDetailsError,
          [name]: true,
        })
      }
    } else if (name === 'cardExpiry') {
      if (!value.match(MM_YY_DATE_FORMAT_REGULAR_EXPRESSION)) {
        setCardDetailsError({
          ...cardDetailsError,
          [name]: true,
        })
      }
    } else if (name === 'cardCvv') {
      if (!value.match(CARD_CVV_REGULAR_EXPRESSION)) {
        setCardDetailsError({
          ...cardDetailsError,
          [name]: true,
        })
      }
    }
  }

  const onClickSubmit = (event: any) => {
    event.preventDefault()
    if (isValidCardDetails) {
      let ccType: CreditCardType | null = null

      let cardType = creditCardType(cardDetails.cardNumber)

      if (cardType && cardType.length > 0 && cardType[0].type) {
        ccType = getCardType(cardType[0].type)
      }

      if (!ccType) {
        setCardDetailsError({
          ...cardDetailsError,
          cardNumber: true,
        })
        return
      } else {
        cardDetails.cardType = ccType
      }

      const [month, year] = cardDetails.cardExpiry.split('/')

      let check = moment()
      let currentMonth = Number(check.format('M'))
      let currentyear = Number(check.format('YY'))
      let inputMonth = Number(month)
      let inputYear = Number(year)

      if (inputYear < currentyear) {
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiry: true,
        })
        return
      } else {
        if (inputYear === currentyear) {
          if (inputMonth < currentMonth) {
            setCardDetailsError({
              ...cardDetailsError,
              cardExpiry: true,
            })
            return
          }
        }
      }

      let cardDetailRequest: GatewayTokenizedCardData = {
        cardType: cardDetails.cardType,
        cardMasked: cardDetails.cardNumber.replace(MASKED_CARD_NUMBER_REGEX, '*'),
        cardExpiryMonth: Number(month),
        cardExpiryYear: Number(year),
      }

      let tokenizeRequest: TokenizeRequest = {
        success: true,
        gatewayTokenizedResult: JSON.stringify(cardDetailRequest),
      }

      if (currentOperatorId && sessionId && redirectUrl) {
        dispatch(setLoading())
        axios({
          method: 'PUT',
          url:
            paymentApiUrl +
            PAYMENT_SESSION_RESPONSE_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
              ':sessionId',
              sessionId,
            ),
          data: tokenizeRequest,
        })
          .then((resp) => {
            dispatch(resetLoading())
            dispatch(setNotification(NotificationType.INFO, ['Card added successfully']))
            window.location.replace(redirectUrl)
          })
          .catch((err) => {
            dispatch(resetLoading())
            dispatch(
              setNotification(NotificationType.ERROR, ['Unexpected error. Please contact your company administrator']),
            )
            console.error(err)
          })
      }
    }
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <h2 className="self-reg-h2">Add a payment card</h2>
          <p className="self-reg-required-text">
            Enter a Visa, Mastercard or American Express and we'll securely store it in your Shuffle account.
          </p>
        </Grid>

        <Grid item xs={12} md={6}>
          <p className="input-label">Card number</p>
          {cardDetailsError.cardNumber && (
            <p className="input-error-label">Please enter a valid visa, Mastercard or American Express card.</p>
          )}
          <TextField
            name={'cardNumber'}
            id="cardNumber"
            InputProps={
              cardDetailsError.cardNumber ? { className: 'shuffleInputError' } : { className: 'shuffleInput' }
            }
            defaultValue=""
            InputLabelProps={{ className: 'shuffleLabel' }}
            variant="outlined"
            margin="dense"
            fullWidth
            value={cardDetails.cardNumber}
            onBlur={(e) => validateField(e)}
            onChange={onChangeField}
            /*  helperText={
              cardDetailsError.cardNumber ? 'Please enter a valid visa, Mastercard or American Express card.' : ' '
            }*/
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <p className="input-label">Card expiry</p>
          {cardDetailsError.cardExpiry && <p className="input-error-label">Please enter a valid expiry date.</p>}
          <TextField
            name={'cardExpiry'}
            id="cardExpiry"
            InputProps={
              cardDetailsError.cardExpiry ? { className: 'shuffleInputError' } : { className: 'shuffleInput' }
            }
            defaultValue=""
            InputLabelProps={{ className: 'shuffleLabel' }}
            variant="outlined"
            margin="dense"
            fullWidth
            onBlur={(e) => validateField(e)}
            value={cardDetails.cardExpiry}
            onChange={onChangeField}
            /*helperText={cardDetailsError.cardExpiry ? 'Please enter a valid expiry date.' : ' '}*/
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <p className="input-label">Security code</p>
          {cardDetailsError.cardCvv && (
            <p className="input-error-label">Please enter the security code for this card.</p>
          )}
          <TextField
            name={'cardCvv'}
            id="cardCvv"
            InputProps={cardDetailsError.cardCvv ? { className: 'shuffleInputError' } : { className: 'shuffleInput' }}
            defaultValue=""
            InputLabelProps={{ className: 'shuffleLabel' }}
            variant="outlined"
            margin="dense"
            fullWidth
            value={cardDetails.cardCvv}
            onBlur={(e) => validateField(e)}
            onChange={onChangeField}
            /*helperText={cardDetailsError.cardCvv ? 'Please enter the security code for this card.' : ' '}*/
          />
        </Grid>

        <Grid item xs={12} md={6}></Grid>

        <Grid item xs={9} style={{ paddingTop: '60px' }}></Grid>
        <Grid item xs={3} style={{ paddingTop: '60px' }}>
          <Button
            className={classes.shuffleButton}
            variant="contained"
            color="primary"
            size="large"
            disabled={!isValidCardDetails}
            onClick={(event) => onClickSubmit(event)}
          >
            {loading ? <CircularProgress size={35} style={{ color: '#FFFFFF' }} /> : 'Add'}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default DefaultAddCard
