import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './version-shuffle.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import configureStore from './store'
import { Provider } from 'react-redux'
import { StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import './i18n'

const store = configureStore(undefined)

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App hostName={window.location.hostname} />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
