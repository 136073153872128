import { CachedAggregates, CachedIdNames, IdOnly, LifecycleOnly } from './types'

export const LIST_AGGREGATES_REQUEST = 'LIST_AGGREGATES_REQUEST'
export const LIST_AGGREGATES_SUCCESS = 'LIST_AGGREGATES_SUCCESS'
export const LIST_AGGREGATES_FAILURE = 'LIST_AGGREGATES_FAILURE'
export const LIST_AGGREGATES_NOT_MODIFIED = 'LIST_AGGREGATES_NOT_MODIFIED'

export const GET_AGGREGATE_REQUEST = 'GET_AGGREGATE_REQUEST'
export const GET_AGGREGATE_SUCCESS = 'GET_AGGREGATE_SUCCESS'
export const GET_AGGREGATE_FAILURE = 'GET_AGGREGATE_FAILURE'

export const UPDATE_AGGREGATE_REQUEST = 'UPDATE_AGGREGATE_REQUEST'
export const UPDATE_AGGREGATE_SUCCESS = 'UPDATE_AGGREGATE_SUCCESS'
export const UPDATE_AGGREGATE_FAILURE = 'UPDATE_AGGREGATE_FAILURE'

export const INSERT_AGGREGATE_REQUEST = 'INSERT_AGGREGATE_REQUEST'
export const INSERT_AGGREGATE_SUCCESS = 'INSERT_AGGREGATE_SUCCESS'
export const INSERT_AGGREGATE_FAILURE = 'INSERT_AGGREGATE_FAILURE'

export const DELETE_AGGREGATE_REQUEST = 'DELETE_AGGREGATE_REQUEST'
export const DELETE_AGGREGATE_SUCCESS = 'DELETE_AGGREGATE_SUCCESS'
export const DELETE_AGGREGATE_FAILURE = 'DELETE_AGGREGATE_FAILURE'

export const LIST_AGGREGATES_ID_NAME_REQUEST = 'LIST_AGGREGATES_ID_NAME_REQUEST'
export const LIST_AGGREGATES_ID_NAME_SUCCESS = 'LIST_AGGREGATES_ID_NAME_SUCCESS'
export const LIST_AGGREGATES_ID_NAME_FAILURE = 'LIST_AGGREGATES_ID_NAME_FAILURE'
export const LIST_AGGREGATES_ID_NAME_NOT_MODIFIED = 'LIST_AGGREGATES_ID_NAME_NOT_MODIFIED'

export const UPDATE_LIFECYCLE_REQUEST = 'UPDATE_LIFECYCLE_REQUEST'
export const UPDATE_LIFECYCLE_SUCCESS = 'UPDATE_LIFECYCLE_SUCCESS'
export const UPDATE_LIFECYCLE_FAILURE = 'UPDATE_LIFECYCLE_FAILURE'

export const RESET_AGGREGATE = 'RESET_AGGREGATE'

export const AGGREGATE_NOTIFICATIONS = 'NOTIFICATIONS'
export const AGGREGATE_INVITATION = 'AUTO_INVITATION'
export const AGGREGATE_MEMBER_APPLICATION = 'MEMBER_APPLICATION'
export const AGGREGATE_EMAIL_VERIFICATION = 'EMAIL_VERIFICATION'
export const AGGREGATE_TOKENISE_INFO = 'TOKENISE_INFO'
export const AGGREGATE_TOKENISE_RESULT = 'AGGREGATE_TOKENISE_RESULT'

export type AggregateTypes =
  | typeof AGGREGATE_NOTIFICATIONS
  | typeof AGGREGATE_INVITATION
  | typeof AGGREGATE_MEMBER_APPLICATION
  | typeof AGGREGATE_EMAIL_VERIFICATION
  | typeof AGGREGATE_TOKENISE_INFO
  | typeof AGGREGATE_TOKENISE_RESULT

export interface ListAggregatesRequestAction {
  type: typeof LIST_AGGREGATES_REQUEST
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface ListAggregatesSuccessAction<T> {
  type: typeof LIST_AGGREGATES_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    aggregates: CachedAggregates<T>
  }
}

export interface ListAggregatesFailureAction {
  type: typeof LIST_AGGREGATES_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ListAggregatesNotModified {
  type: typeof LIST_AGGREGATES_NOT_MODIFIED
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface GetAggregateRequestAction {
  type: typeof GET_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    id: string | null
  }
}

export interface GetAggregateSuccessAction<T> {
  type: typeof GET_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface GetAggregateFailureAction {
  type: typeof GET_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface UpdateAggregateRequestAction<T> {
  type: typeof UPDATE_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface UpdateAggregateSuccessAction<T> {
  type: typeof UPDATE_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface UpdateAggregateFailureAction {
  type: typeof UPDATE_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ResetAggregateAction {
  type: typeof RESET_AGGREGATE
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface InsertAggregateRequestAction<T> {
  type: typeof INSERT_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    aggregate: T
  }
}

export interface InsertAggregateSuccessAction<IdOnly> {
  type: typeof INSERT_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    idOnly: IdOnly
  }
}

export interface InsertAggregateFailureAction {
  type: typeof INSERT_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface DeleteAggregateRequestAction {
  type: typeof DELETE_AGGREGATE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    id: string
  }
}

export interface DeleteAggregateSuccessAction {
  type: typeof DELETE_AGGREGATE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface DeleteAggregateFailureAction {
  type: typeof DELETE_AGGREGATE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ListAggregatesIdNamesRequestAction {
  type: typeof LIST_AGGREGATES_ID_NAME_REQUEST
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface ListAggregatesIdNamesSuccessAction {
  type: typeof LIST_AGGREGATES_ID_NAME_SUCCESS
  payload: {
    aggregateType: AggregateTypes
    idNames: CachedIdNames
  }
}

export interface ListAggregatesIdNamesFailureAction {
  type: typeof LIST_AGGREGATES_ID_NAME_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export interface ListAggregatesIdNamesNotModifiedAction {
  type: typeof LIST_AGGREGATES_ID_NAME_NOT_MODIFIED
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface ResetAggregateAction {
  type: typeof RESET_AGGREGATE
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface UpdateLifeCycleRequestAction<LifeCycleOnly> {
  type: typeof UPDATE_LIFECYCLE_REQUEST
  payload: {
    aggregateType: AggregateTypes
    lifeCycleOnly: LifeCycleOnly
  }
}

export interface UpdateLifeCycleSuccessAction {
  type: typeof UPDATE_LIFECYCLE_SUCCESS
  payload: {
    aggregateType: AggregateTypes
  }
}

export interface UpdateLifeCycleFailureAction {
  type: typeof UPDATE_LIFECYCLE_FAILURE
  payload: {
    aggregateType: AggregateTypes
    error: string
  }
}

export type AggregateActionTypes<T> =
  | ListAggregatesRequestAction
  | ListAggregatesSuccessAction<T>
  | ListAggregatesFailureAction
  | ListAggregatesNotModified
  | GetAggregateRequestAction
  | GetAggregateSuccessAction<T>
  | GetAggregateFailureAction
  | UpdateAggregateRequestAction<T>
  | UpdateAggregateSuccessAction<T>
  | UpdateAggregateFailureAction
  | InsertAggregateRequestAction<T>
  | InsertAggregateSuccessAction<IdOnly>
  | InsertAggregateFailureAction
  | DeleteAggregateRequestAction
  | DeleteAggregateSuccessAction
  | DeleteAggregateFailureAction
  | ListAggregatesIdNamesRequestAction
  | ListAggregatesIdNamesSuccessAction
  | ListAggregatesIdNamesFailureAction
  | ListAggregatesIdNamesNotModifiedAction
  | UpdateLifeCycleRequestAction<LifecycleOnly>
  | UpdateLifeCycleSuccessAction
  | UpdateLifeCycleFailureAction
  | ResetAggregateAction

/**
 * Used to guard for entity actions meant for a different reducer.  Since action.type are the same
 * for all entity actions, this is a convenience function short circuit in entity reducers
 * eg
 *
 if (isAggregateActionType(action.type) &&  (action.payload.aggregateType !== AGGREGATE_MEMBER)){
        console.info("carParkReducer: Not for me - skipping");
        return state;
    }
 * @param actionType action.type supplied to reducer
 */
export const isAggregateActionType: (actionType: any) => Boolean = (actionType) =>
  actionType === LIST_AGGREGATES_REQUEST ||
  actionType === LIST_AGGREGATES_SUCCESS ||
  actionType === LIST_AGGREGATES_FAILURE ||
  actionType === LIST_AGGREGATES_ID_NAME_NOT_MODIFIED ||
  actionType === GET_AGGREGATE_REQUEST ||
  actionType === GET_AGGREGATE_SUCCESS ||
  actionType === GET_AGGREGATE_FAILURE ||
  actionType === UPDATE_AGGREGATE_REQUEST ||
  actionType === UPDATE_AGGREGATE_SUCCESS ||
  actionType === UPDATE_AGGREGATE_FAILURE ||
  actionType === INSERT_AGGREGATE_REQUEST ||
  actionType === INSERT_AGGREGATE_SUCCESS ||
  actionType === INSERT_AGGREGATE_FAILURE ||
  actionType === DELETE_AGGREGATE_REQUEST ||
  actionType === DELETE_AGGREGATE_SUCCESS ||
  actionType === DELETE_AGGREGATE_FAILURE ||
  actionType === LIST_AGGREGATES_ID_NAME_REQUEST ||
  actionType === LIST_AGGREGATES_ID_NAME_SUCCESS ||
  actionType === LIST_AGGREGATES_ID_NAME_FAILURE ||
  actionType === LIST_AGGREGATES_ID_NAME_NOT_MODIFIED ||
  actionType === UPDATE_LIFECYCLE_REQUEST ||
  actionType === UPDATE_LIFECYCLE_SUCCESS ||
  actionType === UPDATE_LIFECYCLE_FAILURE ||
  actionType === RESET_AGGREGATE
