import React from 'react'

interface IProps {}

const Footer: React.FC<IProps> = (props) => {
  return (
    <div id="footer" className="login-page dark-bg">
      <div id="footer-text">
        <p>
          &copy; Wilson Parking 2022. All Rights Reserved. <a href="/privacy-policy">Privacy Policy</a>
          <span style={{ float: 'right' }}>
            V {process.env.REACT_APP_GIT_SHA ? process.env.REACT_APP_GIT_SHA.substring(0, 7) : ''}
          </span>
        </p>
      </div>
    </div>
  )
}
export default Footer
