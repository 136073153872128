import { LoadingState, RESET_LOADING, SET_LOADING, LoadingActionTypes } from './types'

const initialState: LoadingState = {
  loading: false,
}

export function loadingReducer(state = initialState, action: LoadingActionTypes): LoadingState {
  switch (action.type) {
    case SET_LOADING: {
      return {
        loading: true,
      }
    }
    case RESET_LOADING: {
      return {
        ...state,
        loading: false,
      }
    }
    default:
      return state
  }
}
