import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { GatewayType, PAYMENT_SESSION_API_RESOURCE_PATH, TokenizeInfo } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentOperatorId, selectPaymentUrl } from '../../config/app/reducers'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import Loading from '../../components/ui/Loading'
import { resetLoading, setLoading } from '../../store/common/loading/action'
import axios from 'axios'
import { selectLoading } from '../../store/common/loading/types'
import { useQuery } from '../../hook/useQuery'
import AdvamAddCard from '../../components/gateway/advam-add-card'
import DefaultAddCard from '../../components/gateway/default-add-card'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  selfRegModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {}
const CreditCardPage: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const paymentApiUrl = useSelector(selectPaymentUrl)
  const loading = useSelector(selectLoading)
  const [sessionId, setSessionId] = useState<string | null>(null)

  const [tokenizeInfo, setTokenizeInfo] = useState<TokenizeInfo>()

  const query = useQuery()

  useEffect(() => {
    if (query) {
      setSessionId(query.get('sessionId'))
    }
  }, [query])

  useEffect(() => {
    if (sessionId && currentOperatorId && paymentApiUrl) {
      dispatch(setLoading())
      axios({
        method: 'GET',
        url:
          paymentApiUrl +
          PAYMENT_SESSION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(':sessionId', sessionId),
      })
        .then((resp) => {
          dispatch(resetLoading())
          let data: TokenizeInfo = resp.data as TokenizeInfo
          setTokenizeInfo(data)
        })
        .catch((err) => {
          dispatch(resetLoading())
          dispatch(
            setNotification(NotificationType.ERROR, ['Unexpected error. Please contact your company administrator']),
          )
          console.error(err)
        })
    }
  }, [dispatch, sessionId, currentOperatorId, paymentApiUrl])

  return (
    <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
      {loading && <Loading />}
      <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
        {/* //bases on gatewayCode load the page*/}
        {tokenizeInfo && tokenizeInfo.gatewayType === GatewayType.Advam ? (
          <AdvamAddCard
            sessionId={sessionId}
            redirectUrl={tokenizeInfo?.redirectUrl}
            gatewayUrl={tokenizeInfo?.gatewayUrl}
            clientToken={tokenizeInfo?.gatewayRequestData}
          />
        ) : tokenizeInfo && tokenizeInfo.gatewayType === GatewayType.Mock ? (
          <DefaultAddCard tokenizeInfo={tokenizeInfo} sessionId={sessionId} redirectUrl={tokenizeInfo?.redirectUrl} />
        ) : (
          <>{!loading && 'Loading session data'}</>
        )}
      </Container>
    </Box>
  )
}
export default CreditCardPage
