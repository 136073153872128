import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import unavailableIcon from '../../assets/svg/ws-b2b-icon-no_bays.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  selfRegModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {}
const NotFound: React.FC<IProps> = (props) => {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
        <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} className="justified-content">
              <img className="self-reg-icon self-reg-icon-unavailable" src={unavailableIcon} alt="Unavailable" />
              <h2 className="self-reg-h2 centered-h2">404</h2>
              <p className="self-reg-sub-text">
                Unexpected Error, Please try again. If error persist, Please contact your company administrator
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default NotFound
