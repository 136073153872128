import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import acceptedIcon from '../../../assets/svg/ws-b2b-icon-loyalty.svg'
import { Support } from '../../invitation/types'
import { useSelector } from 'react-redux'
import { selectSupportConfig } from '../../invitation/reducer'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  selfRegModal: {
    background: 'white',
    padding: '80px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {}
const ResetPasswordSuccess: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const supportConfig: Support | null = useSelector(selectSupportConfig)

  return (
    <>
      <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
        <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} className="justified-content">
              <img className="self-reg-icon self-reg-icon-accepted" src={acceptedIcon} alt="Accepted" />
              <h2 className="self-reg-h2 centered-h2">Your password changed successfully.</h2>
            </Grid>
            <Grid item xs={12}>
              <p className="self-reg-email-text">
                Please download the app and tap the link within 24 hours or it will expire.
                <br />
                Please contact your company administrator on&nbsp;
                <strong>
                  {supportConfig && supportConfig.email ? (
                    <a href={'mailto:' + supportConfig.email}>{supportConfig.email}</a>
                  ) : (
                    <a href="mailto:shuffle@company.com.au">shuffle@company.com.au</a>
                  )}
                </strong>
                &nbsp; if you need help.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default ResetPasswordSuccess
