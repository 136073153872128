import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import emailIcon from '../../assets/svg/ws-b2b-icon-email.svg'
import { selectCurrentApiKey, selectCurrentOperatorId, selectShuffleUrl } from '../../config/app/reducers'
import axios from 'axios'
import { SERVER_API_KEY_HEADER } from '../../config/constants'
import { RESEND_EMAIL_VERIFICATION_API_RESOURCE_PATH } from '../verification/types'
import { resetLoading, setLoading } from '../../store/common/loading/action'
import { selectLoading } from '../../store/common/loading/types'
import { selectSupportConfig } from '../invitation/reducer'
import { Support } from '../invitation/types'
import Loading from '../../components/ui/Loading'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  selfRegModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {}
const EmailExpired: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const supportConfig: Support | null = useSelector(selectSupportConfig)

  const apiUrl = useSelector(selectShuffleUrl)
  const apiKey = useSelector(selectCurrentApiKey)
  const loading = useSelector(selectLoading)

  const { idOnly } = useSelector(
    (state: RootState) => ({
      idOnly: state.memberApplicationReducer.idOnly,
    }),
    shallowEqual,
  )

  const onClickResendEmail = () => {
    if (idOnly && idOnly.sessionId && currentOperatorId) {
      dispatch(setLoading())
      axios({
        method: 'POST',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        url:
          apiUrl +
          RESEND_EMAIL_VERIFICATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':sessionId',
            idOnly.sessionId,
          ),
      })
        .then((resp) => {
          setTimeout(() => {
            dispatch(setNotification(NotificationType.INFO, ['Email sent successfully.']))
            dispatch(resetLoading())
          }, 2000)
        })
        .catch((err) => {
          dispatch(resetLoading())
          console.error(err)
        })
    }
  }

  return (
    <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
      {loading && <Loading />}
      <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="justified-content">
            <img className="self-reg-icon self-reg-icon-email" src={emailIcon} alt="Email" />
            <h2 className="self-reg-h2 centered-h2">This link has expired, Please check your email.</h2>
            <p className="self-reg-sub-text">
              We’ve sent you an email containing a link to verify your email address. Please click the link to verify
              your email.
            </p>
          </Grid>
          <Grid item xs={12}>
            <p className="self-reg-email-text">
              The email may take up to five minutes to arrive and could be in your spam or junk folders. Please contact
              your company administrator on&nbsp;
              <strong>
                {supportConfig && supportConfig.email ? (
                  <a href={'mailto:' + supportConfig.email}>{supportConfig.email}</a>
                ) : (
                  <a href="mailto:shuffle@company.com.au">shuffle@company.com.au</a>
                )}
              </strong>
              &nbsp;if you need help.
            </p>

            <p>
              <div className="resend-link" onClick={() => onClickResendEmail()}>
                Send the email again
              </div>
            </p>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default EmailExpired
