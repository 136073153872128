import { AggregateState } from '../../common/types'
import { AggregateActionTypes } from '../../common/aggregate.types'

export interface TokenizeInfo {
  operatorId: string
  organisationId: string
  organisationType: string
  gatewayType: GatewayType
  gatewayRequestData: string
  redirectUrl: string
  gatewayUrl: string
}

export enum GatewayType {
  Mock = 'Mock',
  Advam = 'Advam',
}

export interface TokenizeRequest {
  success: boolean
  gatewayTokenizedResult: string
}

export type TokenizeInfoResponseState = AggregateState<TokenizeInfo>
export type TokenizeInfoResponseActionTypes = AggregateActionTypes<TokenizeInfo>

export const PAYMENT_SESSION_API_RESOURCE_PATH = '/public/operators/:operatorId/tokenize-sessions/:sessionId/request'

export const PAYMENT_SESSION_RESPONSE_API_RESOURCE_PATH =
  '/public/operators/:operatorId/tokenize-sessions/:sessionId/response'

export interface CardDetails {
  cardType: string
  cardNumber: string
  cardExpiry: string
  cardCvv: string
}

export interface GatewayTokenizedCardData {
  cardType: string
  cardMasked: string
  cardExpiryYear: number
  cardExpiryMonth: number
  gatewayToken?: string
}

export interface CardDetailsError {
  cardNumber: boolean
  cardExpiry: boolean
  cardCvv: boolean
}

export enum CreditCardType {
  Amex = 'Amex',
  Mastercard = 'Mastercard',
  Visa = 'Visa',
}

export const getCardType = (type: string): CreditCardType => {
  if (type === 'visa') {
    return CreditCardType.Visa
  } else if (type === 'mastercard') {
    return CreditCardType.Mastercard
  } else if (type === 'american-express') {
    return CreditCardType.Amex
  } else {
    return CreditCardType.Visa
  }
}

export const MASK_NON_DIGIT_REGEX = /\D/g
export const MASK_CARD_NUMBER_REGEX = /(\d{4})(?!$)/g
export const MASKED_CARD_NUMBER_REGEX = /[^ ](?=.{4})/g

export const CARD_NUMBER_REGULAR_EXPRESSION = /^(\d{4}\s){3}\d{4}$/

export const MM_YY_DATE_FORMAT_REGULAR_EXPRESSION = /^(0[1-9]|1[0-2])\/(\d{2})$/

export const CARD_CVV_REGULAR_EXPRESSION = /^\d{3}$/
