export const ROUTE_PATH_INVITATION = '/invite/:invitationCode'
export const ROUTE_PATH_INVALID_CODE = '/invalid-code'
export const ROUTE_PATH_ACCOUNT_MANAGEMENT = 'account-management'
export const ROUTE_PATH_EMAIL_EXPIRED = '/email-expired'
export const ROUTE_PATH_VERIFY_MOBILE = '/verify-mobile'
export const ROUTE_PATH_FINAL_COMPLETE = '/final-complete'
export const ROUTE_PATH_ADD_CARD = '/add-card'
export const ROUTE_PATH_NOT_FOUND = '/not-found'
export const ROUTE_PATH_RESET_PASSWORD_SUCCESS = '/reset-password-success'
export const ROUTE_PATH_HOME = '/home'
