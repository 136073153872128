import React, { useEffect } from 'react'
import './App.css'
import { getTenantConfigThunk } from './config/app/thunks'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from './store'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import setUpAxios from './config/axios/axios'
import { useTranslation } from 'react-i18next'
import MainNavigation from './main-navigation'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { setupDatadog } from './config/datadog/datadog'

interface IProps {
  hostName: string
}

const App: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation('', { useSuspense: false })

  useEffect(() => {
    i18n.changeLanguage('en')
  }, [i18n])

  const { tenantConfig } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    console.info('calling getTenantConfig', props.hostName)
    dispatch(getTenantConfigThunk(props.hostName))
  }, [dispatch, props.hostName]) // only called once

  useEffect(() => {
    if (tenantConfig != null) {
      console.info('Applying tenant config', tenantConfig)
      const firebaseConfig = tenantConfig.firebase
      console.info('Applying firebase config', firebaseConfig)
      firebase.initializeApp(firebaseConfig)
      firebase.auth().tenantId = tenantConfig.firebaseAuth.tenantId
      setUpAxios()

      if (tenantConfig.datadog) {
        setupDatadog({
          tenancyCode: tenantConfig.tenant.tenancyCode,
          tenantCode: tenantConfig.tenant.code,
          environment: tenantConfig.config.environment,
          datadogConfig: tenantConfig.datadog,
          gitSha: process.env.REACT_APP_GIT_SHA,
        })
        console.debug('datadog init complete')
      }
    }
  }, [tenantConfig, dispatch])

  console.info('Git sha: ' + process.env.REACT_APP_GIT_SHA)

  return <MainNavigation />
}

export default App
