/**
 * Template method for traced AXIOS operations which retrieves token from the Firebase user in s
 * @param aggregateType
 * @param getState Return RootState to retrieve user from authReducer
 * @param requestCallback
 * @param successCallback
 * @param errorCallback Called back after if the axios returns an error
 * @param ioCallback Execute the axios operation in this callback returning the promise
 */
import { AggregateActionTypes, AggregateTypes } from './aggregate.types'
import { AppThunk, RootState } from '../store'
import {
  getAggregateFailure,
  getAggregateRequest,
  getAggregateSuccess,
  IdentifiableAggregate,
  insertAggregateFailure,
  insertAggregateRequest,
  insertAggregateSuccess,
  listAggregatesFailure,
  listAggregatesNotModified,
  listAggregatesRequest,
  listAggregatesSuccess,
  updateAggregateFailure,
  updateAggregateRequest,
  updateAggregateSuccess,
} from './aggregate-actions'
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { authorizationHeaderConfig, isSuccessWithNotModified } from '../config/axios/axios'
import { MemberApiConfig } from '../config/app/types'
import { CachedAggregates, IdOnly } from './types'
import { ETAG_HEADER, HEADER_LINK_FORMAT, HEADER_X_TOTAL_COUNT, IF_NONE_MATCH_HEADER } from '../config/constants'

const tracedAxiosOperation = <T>(
  aggregateType: AggregateTypes,
  getState: () => RootState,
  requestCallback: () => AggregateActionTypes<T>,
  successCallback: (result: AxiosResponse) => void,
  errorCallback: (error: any) => AggregateActionTypes<T>,
  ioCallback: (config: AxiosRequestConfig) => AxiosPromise,
) => {
  const state = getState()
  const shuffleApiConfig = state.appConfigReducer.memberApiConfig
  const apiKey = state.appConfigReducer.tenantConfig?.tenant.apiKey

  requestCallback()
  return ioCallback(authorizationHeaderConfig(chooseUrl(shuffleApiConfig), apiKey))
    .then((value) => {
      return successCallback(value)
    })
    .catch((error) => {
      return errorCallback(error)
    })
}

export const chooseUrl = (shuffleApiConfig: MemberApiConfig | null) => {
  return shuffleApiConfig ? shuffleApiConfig.shuffleApiUrl : ''
}

export const AGGREGATE_TEAMS = 'TEAMS'
export const AGGREGATE_DEPARTMENT = 'DEPARTMENT'
export const AGGREGATE_TENANCIES = 'TENANCIES'
export const AGGREGATE_DEPARTMENTS_TEAMS = 'DEPARTMENTS_TEAMS'

export const fetchAggregates =
  <T>(aggregateType: AggregateTypes, resourcePath: string, params: any | null): AppThunk<any> =>
  (dispatch, getState) => {
    console.debug('fetchAggregates params:', params)
    const eTagForAggregates = () => {
      switch (aggregateType) {
        default:
          return null
      }
    }
    console.debug('fetchAggregates params:', params)

    const eTag = eTagForAggregates()
    console.debug('fetchAggregates eTag:', eTag)

    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(listAggregatesRequest<T>(aggregateType))
      },
      (response) => {
        if (response.status === 304) {
          console.debug('fetchAggregates 304 returned calling listAggregatesNotModified')
          return dispatch(listAggregatesNotModified<T>(aggregateType))
          // Otherwise, replace data and store ETag response
        } else {
          console.debug('fetchAggregates success:', response.status)
          console.debug('headers', response.headers)
          const aggregates: CachedAggregates<T> = {
            values: response.data,
            eTag: response.headers[ETAG_HEADER],
            totalCount: Number(response.headers[HEADER_X_TOTAL_COUNT]),
            link: response.headers[HEADER_LINK_FORMAT],
          }

          console.debug('aggregates', aggregates)
          return dispatch(listAggregatesSuccess<T>(aggregateType, aggregates))
        }
      },
      (error) => {
        return dispatch(
          listAggregatesFailure<T>(
            aggregateType,
            error.response
              ? error.response.data && error.response.data.error
                ? error.response.data.error
                : error.response.data && error.response.data.errors
                ? error.response.data.errors
                : error.response.data.errors
              : error,
          ),
        )
      },
      (config) => {
        if (eTag != null) {
          config.headers = { [IF_NONE_MATCH_HEADER]: eTag, ...config.headers }
        }
        // 200 | 304 is success
        config.validateStatus = isSuccessWithNotModified
        if (params != null) {
          config.params = params
          console.debug('request params: ', config.params)
        }
        console.debug('request headers: ', config.headers)
        return axios.get<T[]>(resourcePath, config)
      },
    )
  }

/**
 *
 * @param aggregateType
 * @param resourcePath Without trailing slash eg /carparks
 * @param id
 */
export const fetchAggregate =
  <T>(aggregateType: AggregateTypes, resourcePath: string, id: string | null): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType,
      getState,
      () => {
        return dispatch(getAggregateRequest<T>(aggregateType, id))
      },
      (response) => {
        const aggregate: T = response.data
        return dispatch(getAggregateSuccess<T>(aggregateType, aggregate))
      },
      (error) => {
        return dispatch(
          getAggregateFailure<T>(
            aggregateType,
            error.response
              ? error.response.data && error.response.data.error
                ? error.response.data.error
                : error.response.data && error.response.data.errors
                ? error.response.data.errors
                : error.response.data.errors
              : error,
          ),
        )
      },
      (config) => {
        return axios.get<T>(resourcePath + (id ? '/' : '') + (id ? id : ''), config)
      },
    )
  }

/**
 *
 * @param aggregateType
 * @param resourcePath without training slash
 * @param aggregate
 */
export const updateAggregate =
  <T extends IdentifiableAggregate>(aggregateType: AggregateTypes, resourcePath: string, aggregate: T): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType, // 'updateAggregate.' +
      getState,
      () => {
        return dispatch(updateAggregateRequest<T>(aggregateType, aggregate))
      },
      (response) => {
        const aggregate: T = response.data
        return dispatch(updateAggregateSuccess<T>(aggregateType, aggregate))
      },
      (error) => {
        return dispatch(
          updateAggregateFailure<T>(
            aggregateType,
            error.response
              ? error.response.data && error.response.data.error
                ? error.response.data.error
                : error.response.data && error.response.data.errors
                ? error.response.data.errors
                : error.response.data.errors
              : error,
          ),
        )
      },
      (config) => {
        return axios.put<T>(resourcePath + '/' + aggregate.id, aggregate, config)
      },
    )
  }

/**
 * Insert an aggregate.  T is the aggregate type eg CarPark
 * @param aggregateType
 * @param resourcePath without the trailing slash  eg /carparks
 * @param aggregate  Aggregate to insert
 */
export const insertAggregate =
  <T>(aggregateType: AggregateTypes, resourcePath: string, aggregate: T): AppThunk<any> =>
  (dispatch, getState) => {
    return tracedAxiosOperation<T>(
      aggregateType, // 'insertAggregate.' +
      getState,
      () => {
        return dispatch(insertAggregateRequest<T>(aggregateType, aggregate))
      },
      (response) => {
        const idOnly: IdOnly = response.data
        return dispatch(insertAggregateSuccess<T>(aggregateType, idOnly))
      },
      (error) => {
        return dispatch(
          insertAggregateFailure<T>(
            aggregateType,
            error.response
              ? error.response.data && error.response.data.error
                ? error.response.data.error
                : error.response.data && error.response.data.errors
                ? error.response.data.errors
                : error.response.data.errors
              : error,
          ),
        )
      },
      (config) => {
        return axios.post<T>(resourcePath, aggregate, config)
      },
    )
  }
