import {
  AppConfigActionTypes,
  TENANT_CONFIG_FAILURE,
  TENANT_CONFIG_REQUEST,
  TENANT_CONFIG_SUCCESS,
  TenantConfig,
  UPDATE_APP_CONFIG,
} from './types'

export function tenantConfigRequest(): AppConfigActionTypes {
  return {
    type: TENANT_CONFIG_REQUEST,
  }
}

export function tenantConfigSuccess(data: TenantConfig): AppConfigActionTypes {
  return {
    type: TENANT_CONFIG_SUCCESS,
    payload: {
      data: data,
    },
  }
}

export function tenantConfigFailure(error: string): AppConfigActionTypes {
  return {
    type: TENANT_CONFIG_FAILURE,
    payload: {
      error: error,
    },
  }
}

export function updateCoreConfig(url: string): AppConfigActionTypes {
  return {
    type: UPDATE_APP_CONFIG,
    payload: {
      url: url,
    },
  }
}
