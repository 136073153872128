import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IconButton, TextField } from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import {
  isValidAll,
  passwordMatch,
  validateHasOneDigit,
  validateHasOneLower,
  validateHasOneSpecialCharacter,
  validateHasOneUpper,
  validPasswordLength,
} from '../../util/validator'
import { clearNotification, setNotification } from '../../../store/common/notifications/action'
import { resetLoading, setLoading } from '../../../store/common/loading/action'
import { getAuth, confirmPasswordReset, checkActionCode } from 'firebase/auth'
import { NotificationType } from '../../../store/common/notifications/types'
import { selectLoading } from '../../../store/common/loading/types'
import { useNavigate } from 'react-router'
import Container from '@mui/material/Container'
import axios from 'axios'
import { SERVER_API_KEY_HEADER } from '../../../config/constants'
import { RESEND_PASSWORD_RESET_API_RESOURCE_PATH, UPDATE_PASSWORD_RESET_API_RESOURCE_PATH } from '../types'
import { selectCurrentApiKey, selectCurrentOperatorId, selectShuffleUrl } from '../../../config/app/reducers'
import Loading from '../../../components/ui/Loading'
import EmailResendPage from '../common/email-resend'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  selfRegModal: {
    background: 'white',
    padding: '80px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {
  oobCode: string | null
  verificationSessionId: string
}
const ResetPassword: React.FC<IProps> = (props) => {
  const { oobCode, verificationSessionId } = props
  const classes = useStyles()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const loading = useSelector(selectLoading)

  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [repeatPassword, setRepeatPassword] = useState<string | null>(null)

  const [hasCharacterLength, setHasCharacterLength] = useState<boolean>(false)
  const [hasOneLower, setHasOneLower] = useState<boolean>(false)
  const [hasOneUpper, setHasOneUpper] = useState<boolean>(false)
  const [hasOneNumber, setHasOneNumber] = useState<boolean>(false)
  const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false)
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(false)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false)

  const [validOOBCode, setValidOOObCode] = useState<boolean | null>(null)

  const apiUrl = useSelector(selectShuffleUrl)
  const apiKey = useSelector(selectCurrentApiKey)
  const currentOperatorId = useSelector(selectCurrentOperatorId)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword)
  }

  const handleMouseDownRepeatPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (oobCode) {
      dispatch(setLoading())
      const auth = getAuth()
      checkActionCode(auth, oobCode)
        .then((resp) => {
          console.dir('oobCode is valid')
          dispatch(resetLoading())
          setValidOOObCode(true)
        })
        .catch((error) => {
          dispatch(resetLoading())
          //resetPassword link with valid oobcode
          onClickResendResetPassword(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oobCode])

  const onClickResendResetPassword = (showNotification?: boolean) => {
    if (apiKey && currentOperatorId && verificationSessionId) {
      dispatch(setLoading())
      axios({
        method: 'POST',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        url:
          apiUrl +
          RESEND_PASSWORD_RESET_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':sessionId',
            verificationSessionId,
          ),
        data: {},
      })
        .then((resp) => {
          dispatch(resetLoading())
          setValidOOObCode(false)
          if (showNotification) {
            dispatch(setNotification(NotificationType.INFO, ['Email sent successfully.']))
          }
        })
        .catch((err) => {
          dispatch(resetLoading())
          dispatch(
            setNotification(NotificationType.ERROR, [
              "Something went wrong. Please click on 'Send the email again' link",
            ]),
          )
        })
    }
  }

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget
    if (name === 'newPassword') {
      setNewPassword(value)
      if (validPasswordLength(value)) {
        setHasCharacterLength(true)
      } else {
        setHasCharacterLength(false)
      }
      if (validateHasOneUpper(value)) {
        setHasOneUpper(true)
      } else {
        setHasOneUpper(false)
      }
      if (validateHasOneLower(value)) {
        setHasOneLower(true)
      } else {
        setHasOneLower(false)
      }

      if (validateHasOneDigit(value)) {
        setHasOneNumber(true)
      } else {
        setHasOneNumber(false)
      }
      if (validateHasOneSpecialCharacter(value)) {
        setHasSpecialChar(true)
      } else {
        setHasSpecialChar(false)
      }
      if (newPassword && repeatPassword) {
        if (passwordMatch(value, repeatPassword)) {
          setIsPasswordMatch(true)
        } else {
          setIsPasswordMatch(false)
        }
      }
    } else if (name === 'repeatPassword') {
      setRepeatPassword(value)
      if (newPassword && repeatPassword) {
        if (passwordMatch(newPassword, value)) {
          setIsPasswordMatch(true)
        } else {
          setIsPasswordMatch(false)
        }
      }
    }
  }
  const onClickResetPasswordConfirm = (event: any) => {
    event.preventDefault()
    if (
      oobCode &&
      newPassword &&
      repeatPassword &&
      isValidAll(newPassword, repeatPassword) &&
      apiKey &&
      currentOperatorId &&
      verificationSessionId
    ) {
      dispatch(clearNotification())
      dispatch(setLoading())
      const auth = getAuth()
      confirmPasswordReset(auth, oobCode, newPassword)
        .then(() => {
          dispatch(resetLoading())
          axios({
            method: 'POST',
            headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
            url:
              apiUrl +
              UPDATE_PASSWORD_RESET_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                ':sessionId',
                verificationSessionId,
              ),
            data: {},
          })
            .then((resp) => {
              dispatch(resetLoading())
              navigate('/reset-password-success')
            })
            .catch((err) => {
              dispatch(resetLoading())
              dispatch(setNotification(NotificationType.ERROR, ['Something went wrong, Please try again']))
            })
        })
        .catch((error) => {
          dispatch(resetLoading())
          dispatch(
            setNotification(NotificationType.ERROR, ['Reset Password link has expired, or has already been used']),
          )
        })
    }
  }

  return (
    <>
      <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
        {loading && <Loading />}

        {validOOBCode === true && (
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} className="justified-content">
              <h2 className="self-reg-h2 centered-h2">Choose a password</h2>
            </Grid>

            <Grid item xs={12}>
              <p className="input-label">New password</p>
              <TextField
                inputProps={{ className: 'shuffleInput', tabIndex: 1 }}
                id="newPassword"
                name="newPassword"
                InputLabelProps={{ className: 'shuffleLabel' }}
                variant="outlined"
                margin="dense"
                size={'small'}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onChange={onChangeHandler}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseDown={handleMouseDownPassword}
                      style={{ width: 31, height: 15, marginTop: 0 }}
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize={'medium'} onClick={handleClickShowPassword} />
                      ) : (
                        <Visibility fontSize={'medium'} onClick={handleClickShowPassword} />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="input-label">Repeat new password</p>
              <TextField
                inputProps={{ className: 'shuffleInput', tabIndex: 2 }}
                id="repeatPassword"
                name="repeatPassword"
                InputLabelProps={{ className: 'shuffleLabel' }}
                variant="outlined"
                size={'small'}
                margin="dense"
                type={showRepeatPassword ? 'text' : 'password'}
                fullWidth
                onChange={onChangeHandler}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseDown={handleMouseDownRepeatPassword}
                      style={{ width: 31, height: 15, marginTop: 0 }}
                    >
                      {showRepeatPassword ? (
                        <VisibilityOff fontSize={'medium'} onClick={handleClickShowRepeatPassword} />
                      ) : (
                        <Visibility fontSize={'medium'} onClick={handleClickShowRepeatPassword} />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="password-tips">A FEW TIPS:</p>
              <ul className="password-tips-list">
                <li className={hasCharacterLength ? 'password-accepted' : ''}>Use between 8 and 16 characters</li>
                <li className={hasOneLower ? 'password-accepted' : ''}>At least one lowercase character</li>
                <li className={hasOneUpper ? 'password-accepted' : ''}>At least one uppercase character</li>
                <li className={hasOneNumber ? 'password-accepted' : ''}>At least one number</li>
                <li className={hasSpecialChar ? 'password-accepted' : ''}>At least one special character (e.g. ?#!)</li>
                <li className={isPasswordMatch ? 'password-accepted' : ''}>The same password entered twice</li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.shuffleButton}
                variant="contained"
                color="primary"
                size="large"
                disabled={newPassword && repeatPassword && isValidAll(newPassword, repeatPassword) ? false : true}
                onClick={(event) => onClickResetPasswordConfirm(event)}
                tabIndex={9}
              >
                Set password
              </Button>
            </Grid>
          </Grid>
        )}

        {validOOBCode === false && <EmailResendPage onClickResend={onClickResendResetPassword} />}
      </Container>
    </>
  )
}

export default ResetPassword
