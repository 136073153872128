import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import React, { Dispatch, SetStateAction, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import phoneIcon from '../../../assets/svg/ws-b2b-icon-mobile-2.svg'
import { Error, ErrorResponse } from '../../../common/types'
import { selectCurrentApiKey, selectCurrentOperatorId, selectShuffleUrl } from '../../../config/app/reducers'
import { SERVER_API_KEY_HEADER } from '../../../config/constants'
import { resetLoading, setLoading } from '../../../store/common/loading/action'
import { setNotification } from '../../../store/common/notifications/action'
import { NotificationType } from '../../../store/common/notifications/types'
import { MemberSessionInfo, RESEND_SMS_VERIFICATION_API_RESOURCE_PATH, SMS_VERIFIED_API_RESOURCE_PATH } from '../types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '1rem',
    width: '20%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
    zIndex: 5000,
  },
  selfRegModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    minHeight: '300px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {
  OTPValue: string
  setOTPValue: Dispatch<SetStateAction<string>>
  isMobileNumberVerification: boolean
  verificationSmsId: string
}
const SmsVerification: React.FC<IProps> = (props) => {
  const { OTPValue, setOTPValue, isMobileNumberVerification, verificationSmsId } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const apiUrl = useSelector(selectShuffleUrl)
  const apiKey = useSelector(selectCurrentApiKey)
  const navigate = useNavigate()

  const [invalidCode, setInvalidCode] = useState<boolean>(false)
  const [isNextButtonDisable, setIsNextButtonDisable] = useState<boolean>(true)
  const [invalidCodeError, setInvalidCodeError] = useState<string | null>(null)

  const onClickVerifySms = (event: any) => {
    if (OTPValue && OTPValue.length === 6 && verificationSmsId && currentOperatorId) {
      setInvalidCode(false)
      setInvalidCodeError(null)
      dispatch(setLoading())
      axios({
        method: 'POST',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        data: { code: OTPValue },
        url:
          apiUrl +
          SMS_VERIFIED_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':sessionId',
            verificationSmsId ? verificationSmsId : '',
          ),
      })
        .then((resp) => {
          let memberSessionInfo: MemberSessionInfo = resp.data as MemberSessionInfo
          setInvalidCode(false)
          dispatch(resetLoading())
          navigate('/final-complete', {
            state: { data: memberSessionInfo },
          })
        })
        .catch((err: any) => {
          setInvalidCode(true)
          if (err.response.data && err.response.data.errors) {
            let error: ErrorResponse[] = err.response.data.errors
            if (error && error.length > 0) {
              let firstError: Error = error[0]
              if (firstError.message === 'Expired') {
                setInvalidCodeError(
                  "The verification code has expired. For a new code click the 'Send the code again' link below.",
                )
                setIsNextButtonDisable(true)
              } else {
                setInvalidCodeError("The verification code you entered isn't correct. Please try again.")
                setIsNextButtonDisable(true)
              }
            } else {
              setInvalidCodeError("The verification code you entered isn't correct. Please try again.")
              setIsNextButtonDisable(true)
            }
          } else {
            setInvalidCodeError("The verification code you entered isn't correct. Please try again.")
            setIsNextButtonDisable(true)
          }
          setOTPValue('')
          dispatch(resetLoading())
        })
    }
  }

  const onClickResendSms = () => {
    if (currentOperatorId && verificationSmsId) {
      setInvalidCode(false)
      setInvalidCodeError(null)
      dispatch(setLoading())
      axios({
        method: 'POST',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        url:
          apiUrl +
          RESEND_SMS_VERIFICATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':sessionId',
            verificationSmsId ? verificationSmsId : '',
          ),
        data: {},
      })
        .then((resp) => {
          dispatch(setNotification(NotificationType.INFO, ['Sms sent successfully.']))
          dispatch(resetLoading())
        })
        .catch((err) => {
          const errors: Error[] = JSON.parse(JSON.stringify(err))
          if (errors.length > 0) {
            let firstError: Error = errors[0]
            dispatch(
              setNotification(NotificationType.ERROR, [
                firstError.message
                  ? firstError.message
                  : firstError.code === '404'
                  ? 'Unexpected error, Please try again'
                  : firstError.code,
              ]),
            )
          }
          dispatch(resetLoading())
        })
    }
  }

  const onChangeOptValue = (value: string) => {
    setInvalidCode(false)
    setOTPValue(value)
    if (value && value.trim().length === 6) {
      setIsNextButtonDisable(false)
    } else {
      setIsNextButtonDisable(true)
    }
  }

  return (
    <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} className="justified-content" style={{ marginBottom: 16 }}>
          <img className="self-reg-icon self-reg-icon-accepted" src={phoneIcon} alt="Accepted" />
          {!isMobileNumberVerification ? (
            <>
              <h2 className="self-reg-h2 centered-h2">Last step, enter the SMS verification code</h2>
              <p className="self-reg-sub-text">
                Please enter the six digit verification code we've sent in a text message to your new mobile.
              </p>
            </>
          ) : (
            <>
              <h2 className="self-reg-h2 centered-h2">
                Please enter the six digit verification code we've sent in a text message to your new mobile.
              </h2>
            </>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginBottom: 8,
            padding: 8,
            textAlign: 'center',
          }}
        >
          <OtpInput
            value={OTPValue}
            onChange={onChangeOptValue}
            numInputs={6}
            separator={<span>&nbsp;</span>}
            inputStyle={invalidCode ? 'inputOtpStyleError' : 'inputOtpStyle'}
            containerStyle="containerOtpStyle"
            isInputNum
          />
        </Grid>
        <Grid item xs={12}>
          {invalidCode && <p className="self-reg-sms-error-text">{invalidCodeError}</p>}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Button
            className={classes.shuffleButton}
            variant="contained"
            color="primary"
            size="large"
            onClick={(event) => onClickVerifySms(event)}
            disabled={isNextButtonDisable}
          >
            Next
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <div className="resend-link" onClick={() => onClickResendSms()}>
            Send the code again
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
export default SmsVerification
