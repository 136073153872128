export const PAGE_TITLE_LOGIN = 'Login'
export const PAGE_TITLE_HOME = 'Home'

export const SERVER_API_TIMEOUT = 1 * 30 * 1000
export const SERVER_API_KEY_HEADER = 'x-api-key'
export const AUTHORIZATION_HEADER = 'Authorization'
export const BEARER_PREFIX = 'Bearer '

export const HEADER_X_TOTAL_COUNT = 'x-total-count'
export const HEADER_LINK_FORMAT = 'link'

export const ETAG_HEADER = 'etag'
export const IF_NONE_MATCH_HEADER = 'If-None-Match'
