import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Invite from './pages/invitation/invite'
import InvalidCode from './pages/invalid-code'
import MainLayout from './layout/main-layout'
import Home from './pages/home'
import VerifyEmail from './pages/verification'
import NotFound from './pages/not-found'
import ResetPasswordSuccess from './pages/verification/reset-password/success'
import FinalSuccess from './pages/verification/final/success'
import EmailExpired from './pages/email-verification-expired'
import CreditCardPage from './pages/credit-card'
import {
  ROUTE_PATH_ACCOUNT_MANAGEMENT,
  ROUTE_PATH_ADD_CARD,
  ROUTE_PATH_EMAIL_EXPIRED,
  ROUTE_PATH_FINAL_COMPLETE,
  ROUTE_PATH_HOME,
  ROUTE_PATH_INVALID_CODE,
  ROUTE_PATH_INVITATION,
  ROUTE_PATH_NOT_FOUND,
  ROUTE_PATH_RESET_PASSWORD_SUCCESS,
  ROUTE_PATH_VERIFY_MOBILE,
} from './pages/util/constant'

interface IProps {}

const MainNavigation: React.FC<IProps> = (props) => {
  return (
    <>
      <Router>
        <Routes>
          <Route path={ROUTE_PATH_INVITATION} element={<MainLayout />}>
            <Route index element={<Invite />} />
          </Route>
          <Route path={ROUTE_PATH_INVALID_CODE} element={<MainLayout />}>
            <Route index element={<InvalidCode />} />
          </Route>

          <Route path={ROUTE_PATH_ACCOUNT_MANAGEMENT} element={<MainLayout />}>
            <Route index element={<VerifyEmail />} />
          </Route>

          <Route path={ROUTE_PATH_EMAIL_EXPIRED} element={<MainLayout />}>
            <Route index element={<EmailExpired />} />
          </Route>

          <Route path={ROUTE_PATH_VERIFY_MOBILE} element={<MainLayout />}>
            <Route index element={<VerifyEmail />} />
          </Route>

          <Route path={ROUTE_PATH_FINAL_COMPLETE} element={<MainLayout />}>
            <Route index element={<FinalSuccess />} />
          </Route>

          <Route path={ROUTE_PATH_ADD_CARD} element={<MainLayout />}>
            <Route index element={<CreditCardPage />} />
          </Route>

          <Route path={ROUTE_PATH_NOT_FOUND} element={<MainLayout />}>
            <Route index element={<NotFound />} />
          </Route>

          <Route path={ROUTE_PATH_RESET_PASSWORD_SUCCESS} element={<MainLayout />}>
            <Route index element={<ResetPasswordSuccess />} />
          </Route>

          <Route path={ROUTE_PATH_HOME} element={<MainLayout />}>
            <Route index element={<Home />} />
          </Route>

          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
          </Route>

          <Route path="*" element={<MainLayout />}>
            <Route index element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </>
  )
}

export default MainNavigation
