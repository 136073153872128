import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetLoading, setLoading } from '../../store/common/loading/action'
import { AdvamGatewayResponse, FiledState, GatewayResponse } from './types'
import '../../assets/css/hosted-fields.css'
import { PAYMENT_SESSION_RESPONSE_API_RESOURCE_PATH, TokenizeRequest } from '../../pages/credit-card/types'
import axios from 'axios'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import { selectCurrentOperatorId, selectPaymentUrl } from '../../config/app/reducers'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { selectLoading } from '../../store/common/loading/types'

const useStyles = makeStyles((theme) => ({
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
}))

interface IProps {
  sessionId?: string | null
  redirectUrl?: string | null
  gatewayUrl?: string | null
  clientToken?: string | null
}

const AdvamAddCard: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { sessionId, redirectUrl, gatewayUrl, clientToken } = props

  const dispatch = useDispatch()
  const loading = useSelector(selectLoading)
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const paymentApiUrl = useSelector(selectPaymentUrl)

  const [hostedConfigLoaded, setHostedConfigLoaded] = useState(false)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [webBankSessionInitialised, setSebBankSessionInitialised] = useState<boolean>(false)

  const [webBankJs, setWebBankJs] = useState<WebbankJs | null>(null)
  const [disabledPay, setDisabledPay] = useState<boolean>(true)
  const [tokenizationResponse, setTokenizationResponse] = useState<AdvamGatewayResponse | null>(null)
  const [hostedFieldsConfig, setHostedFieldsConfig] = useState<any | null>(null)

  const [cardNumberFiledState, setCardNumberFiledState] = useState<FiledState | null>(null)
  const [cvvFiledState, setCvvFiledState] = useState<FiledState | null>(null)
  const [expiryMonthFiledState, setExpiryMonthFiledState] = useState<FiledState | null>(null)
  const [expiryYearFiledState, setExpiryYeatFiledState] = useState<FiledState | null>(null)

  useMemo(() => {
    const setHostedConfig = () => {
      dispatch(setLoading())
      let hostedFieldsConfiguration = {
        fields: {
          cardNumber: {
            containerId: 'hosted-card-number',
            placeholder: 'Card Number',
            fieldName: 'Card Number',
          },
          expiryDateMonth: {
            containerId: 'hosted-expiry-date-month',
            placeholder: 'MM',
            format: 'MM',
            fieldName: 'Expiry Date Month',
            type: 'input',
          },
          expiryDateYear: {
            containerId: 'hosted-expiry-date-year',
            placeholder: 'YY',
            format: 'YY',
            fieldName: 'Expiry Date Year',
            type: 'input',
          },

          cvv: {
            containerId: 'hosted-cvv',
            placeholder: 'CVV',
            fieldName: 'Card Verification Value',
            mask: true,
          },
        },
        events: {
          onFocus: onFocus,
          onBlur: onBlur,
          onInput: onInput,
          onCardTypeChange: onCardTypeChange,
        },
        styles: {
          hostedFieldInvalid: {
            color: 'red',
          },
          hostedFieldValid: {
            color: 'green',
          },
          hostedFieldFocus: {
            color: '#008dca',
          },
          hostedField: {
            'background-color': 'transparent',
            color: 'black',
            padding: '8px 16px',
            'font-size': '14px',
            'font-family': 'Untitled Sans, sans-serif',
          },
        },
        language: 'en',
        supportedCardTypes: ['VISA', 'MASTERCARD', 'AMEX'],
      }
      setHostedFieldsConfig(hostedFieldsConfiguration)
      setHostedConfigLoaded(true)
      dispatch(resetLoading())
    }

    if (!hostedConfigLoaded) {
      setHostedConfig()
    }
    // eslint-disable-next-line
  }, [dispatch, hostedConfigLoaded])

  useEffect(() => {
    console.log(disabledPay)
    if (cardNumberFiledState && expiryYearFiledState && expiryMonthFiledState && cvvFiledState) {
      if (
        cardNumberFiledState.valid &&
        expiryYearFiledState.valid &&
        expiryMonthFiledState.valid &&
        cvvFiledState.valid
      ) {
        setDisabledPay(false)
      } else {
        setDisabledPay(true)
      }
    } else {
      setDisabledPay(true)
    }
    // eslint-disable-next-line
  }, [cardNumberFiledState, expiryYearFiledState, expiryMonthFiledState, cvvFiledState])

  useEffect(() => {
    const loadScript = () => {
      dispatch(setLoading())
      const script = document.createElement('script')
      script.src = `${gatewayUrl}/webbank/webbank-js/js/v2/webbank.js`
      script.async = true
      script.onload = () => setScriptLoaded(true)
      document.body.appendChild(script)
      //dispatch(resetLoading())
    }

    if (!scriptLoaded && hostedConfigLoaded && gatewayUrl) {
      loadScript()
    }

    return () => {
      // Clean up the script when the component unmounts
      const scriptElement = document.querySelector(`script[src="${gatewayUrl}/webbank/webbank-js/js/v2/webbank.js"]`)
      if (scriptElement) {
        scriptElement.remove()
      }
    }
  }, [dispatch, scriptLoaded, hostedConfigLoaded, gatewayUrl])

  useEffect(() => {
    if (scriptLoaded && clientToken) {
      //dispatch(setLoading())
      const webbankJs = new WebbankJs()
      webbankJs
        .initialise(clientToken)
        .then(() => {
          //dispatch(resetLoading())
          console.log('Web Bank session Initialisation success')
          setWebBankJs(webbankJs)
          setSebBankSessionInitialised(true)
        })
        .catch(() => {
          dispatch(resetLoading())
          setWebBankJs(webbankJs)
          setSebBankSessionInitialised(false)
          dispatch(setNotification(NotificationType.ERROR, ['Web Bank session initialization failed']))
          console.log(' Web Bank session Initialisation failed ')
        })
    }
  }, [dispatch, scriptLoaded, clientToken])

  useEffect(() => {
    if (webBankSessionInitialised && webBankJs && hostedFieldsConfig) {
      //dispatch(setLoading())
      webBankJs.hostedFields
        .initialise(hostedFieldsConfig)
        .then(() => {
          dispatch(resetLoading())
          console.log('Web Bank session hosted fields Initialisation success')
        })
        .catch(() => {
          dispatch(resetLoading())
          dispatch(setNotification(NotificationType.ERROR, ['Web Bank session hosted fields initialization failed']))
          console.log(' Web Bank session hosted fields Initialisation failed ')
        })
    }
    // eslint-disable-next-line
  }, [dispatch, webBankSessionInitialised, webBankJs, hostedFieldsConfig])

  function onFocus(event: FiledState) {}
  function onBlur(event: FiledState) {}
  function onInput(event: FiledState) {
    let { fieldKey } = event
    if (fieldKey === 'cardNumber') {
      setCardNumberFiledState(event)
    } else if (fieldKey === 'expiryDateMonth') {
      setExpiryMonthFiledState(event)
    } else if (fieldKey === 'expiryDateYear') {
      setExpiryYeatFiledState(event)
    } else if (fieldKey === 'cvv') {
      setCvvFiledState(event)
    }
  }

  function onCardTypeChange(event: FiledState) {}

  function submitHostedFields(event: any) {
    if (webBankJs) {
      dispatch(setLoading())
      setDisabledPay(true)

      // Check the tokenisation data is not present to prevent the default form submit action and submit the hosted fields for ADVAM processing.
      if (!tokenizationResponse) {
        // Prevent the default form action.
        event.preventDefault()
        // Create the supported hosted fields request fields.
        var hostedFieldsRequest = {}
        // Call the hosted fields submit operation and wait for the event handlers to be called back.
        webBankJs.hostedFields
          .submit(hostedFieldsRequest)
          .then((tokenisationResponse: AdvamGatewayResponse) => {
            dispatch(resetLoading())
            setTokenizationResponse(tokenisationResponse)

            let tokenizeRequest: TokenizeRequest = {
              success: true,
              gatewayTokenizedResult: tokenisationResponse.signature,
            }

            if (currentOperatorId && sessionId && redirectUrl) {
              dispatch(setLoading())
              axios({
                method: 'PUT',
                url:
                  paymentApiUrl +
                  PAYMENT_SESSION_RESPONSE_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                    ':sessionId',
                    sessionId,
                  ),
                data: tokenizeRequest,
              })
                .then((resp) => {
                  dispatch(resetLoading())
                  dispatch(setNotification(NotificationType.INFO, ['Card added successfully']))
                  window.location.replace(redirectUrl)
                })
                .catch((err) => {
                  dispatch(resetLoading())
                  dispatch(
                    setNotification(NotificationType.ERROR, [
                      'Unexpected error. Please contact your company administrator',
                    ]),
                  )
                  console.error(err)
                })
            }
          })
          .catch((error: GatewayResponse) => {
            setTokenizationResponse(null)
            dispatch(resetLoading())
            dispatch(setNotification(NotificationType.ERROR, [error.message]))
          })
      }
    }
  }

  return (
    <form id="payment-form" name="payment-form" method="post">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <h2 className="self-reg-h2">Add a payment card</h2>
          <p className="self-reg-required-text">
            Enter a Visa, Mastercard or American Express and we'll securely store it in your Shuffle account.
          </p>
        </Grid>
        <Grid item xs={12} md={6} className="hosted-field-group">
          <p className="input-label">Card number</p>
          <p
            id="hosted-card-number-error-message"
            //htmlFor="hosted-card-number"
            className="hosted-field-error-message-hidden"
          ></p>
          <div id="hosted-card-number" className="hosted-field">
            {/* Card number hosted field will be added here. */}
          </div>
        </Grid>

        <Grid item xs={12} md={6} className="hosted-field-group">
          <p className="input-label">Expiry Month</p>
          <p
            id="hosted-expiry-date-month-error-message"
            //htmlFor="hosted-expiry-date-month"
            className="hosted-field-error-message-hidden"
          ></p>
          <div id="hosted-expiry-date-month" className="hosted-field">
            {/* Expiry date month hosted field will be added here. */}
          </div>
        </Grid>

        <Grid item xs={12} md={6} className="hosted-field-group">
          <p className="input-label">Expiry Year</p>
          <p
            id="hosted-expiry-date-year-error-message"
            //htmlFor="hosted-expiry-date-year"
            className="hosted-field-error-message-hidden"
          ></p>
          <div id="hosted-expiry-date-year" className="hosted-field">
            {/* Expiry date year hosted field will be added here. */}
          </div>
        </Grid>

        <Grid item xs={12} md={6} className="hosted-field-group">
          <p className="input-label"> Card Verification Value</p>
          <p
            id="hosted-cvv-error-message"
            //htmlFor="hosted-cvv"
            className="hosted-field-error-message-hidden"
          ></p>
          <div id="hosted-cvv" className="hosted-field">
            {/* CVV hosted field will be added here. */}
          </div>
        </Grid>

        <Grid item xs={12} md={12}></Grid>

        <Grid item xs={9} style={{ paddingTop: '60px' }}></Grid>
        <Grid item xs={3} style={{ paddingTop: '60px' }}>
          <Button
            name="payment-button"
            id="payment-button"
            className={classes.shuffleButton}
            variant="contained"
            color="primary"
            size="large"
            disabled={disabledPay}
            onClick={submitHostedFields}
          >
            {loading ? <CircularProgress size={35} style={{ color: '#FFFFFF' }} /> : 'Add'}
          </Button>
        </Grid>
      </Grid>
      <input name="tokenisation-response" id="tokenisation-response" type="hidden" />
    </form>
  )
}

export default AdvamAddCard
