import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import acceptedIcon from '../../../assets/svg/ws-b2b-icon-loyalty.svg'
import Box from '@mui/material/Box'
import { Support } from '../../invitation/types'
import { useSelector } from 'react-redux'
import { selectSupportConfig } from '../../invitation/reducer'
import { useLocation } from 'react-router'
import { MemberSessionInfo, UserSessionState, UserSessionStatus } from '../types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  selfRegModal: {
    background: 'white',
    padding: '80px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {}
const FinalSuccess: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const supportConfig: Support | null = useSelector(selectSupportConfig)
  const { state }: any = useLocation()

  const [memberSessionInfo, setMemberSessionInfo] = useState<MemberSessionInfo | null>(null)

  useEffect(() => {
    if (state) {
      let memberSessionInfo: MemberSessionInfo = state.data as MemberSessionInfo
      if (memberSessionInfo) {
        setMemberSessionInfo(memberSessionInfo)
      } else {
        setMemberSessionInfo(null)
      }
    }
  }, [state])

  return (
    <>
      <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
        <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} className="justified-content">
              <img className="self-reg-icon self-reg-icon-accepted" src={acceptedIcon} alt="Accepted" />
              {memberSessionInfo &&
              memberSessionInfo.sessionStatus === UserSessionStatus.Queued &&
              (memberSessionInfo.sessionState === UserSessionState.PasswordReset ||
                memberSessionInfo.sessionState === UserSessionState.Completed) ? (
                <>
                  <h2 className="self-reg-h2 centered-h2">Thanks, you’re ready to start parking.</h2>
                  {memberSessionInfo.sessionState === UserSessionState.PasswordReset && (
                    <p className="self-reg-sub-text">
                      We’ve sent a message to your mobile phone number with a link to download the Shuffle Parking App.
                      Tap that link on your phone to start parking.
                    </p>
                  )}
                </>
              ) : (
                <>
                  <h2 className="self-reg-h2 centered-h2">Thanks, your new mobile is now verified.</h2>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {memberSessionInfo &&
              memberSessionInfo.sessionState === UserSessionState.PasswordReset &&
              memberSessionInfo.sessionStatus === UserSessionStatus.Queued ? (
                <>
                  <p className="self-reg-email-text">
                    Please download the app and tap the link within 24 hours or it will expire.
                    <br />
                    Please contact your company administrator on&nbsp;
                    <strong>
                      {supportConfig && supportConfig.email ? (
                        <a href={'mailto:' + supportConfig.email}>{supportConfig.email}</a>
                      ) : (
                        <a href="mailto:shuffle@company.com.au">shuffle@company.com.au</a>
                      )}
                    </strong>
                    &nbsp; if you need help.
                  </p>
                </>
              ) : (
                <>
                  <p className="self-reg-email-text">
                    Please contact your company administrator on&nbsp;
                    <strong>
                      {supportConfig && supportConfig.email ? (
                        <a href={'mailto:' + supportConfig.email}>{supportConfig.email}</a>
                      ) : (
                        <a href="mailto:shuffle@company.com.au">shuffle@company.com.au</a>
                      )}
                    </strong>
                    &nbsp; if you need help.
                  </p>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default FinalSuccess
