import thunkMiddleware, { ThunkAction } from 'redux-thunk'
import { createStore, applyMiddleware, combineReducers, Action } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { appConfigReducer } from '../config/app/reducers'
import { errorReducer } from './common/error/errorReducer'
import { loadingReducer } from './common/loading/loadingReducer'
import { notificationsReducer } from './common/notifications/reducer'
import { invitationReducer } from '../pages/invitation/reducer'
import { memberApplicationReducer } from './common/member-application/reducer'

const loggerMiddleware = createLogger()

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

export const rootReducer = combineReducers({
  appConfigReducer,
  errorReducer,
  loadingReducer,
  notificationsReducer,
  invitationReducer,
  memberApplicationReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<R> = ThunkAction<R, RootState, null, Action>

export default function configureStore(preloadedState: RootState | undefined) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)),
  )
  return store
}
