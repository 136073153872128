import { AggregateState, IdClientRefName, OrganisationType } from '../../common/types'
import { AggregateActionTypes } from '../../common/aggregate.types'
import { UserSessionState, UserSessionStatus, UserSessionType } from '../verification/types'

export interface InvitationCodeResponse {
  organisationName: string
  organisationType: OrganisationType | null
  organisationId: string | null
  departmentTeams: DepartmentTeamListItemDto[] | null
  branding: CarParkTenantBrandingDto | null
  facilities: IdClientRefName[]
  support: Support
  sessionId: string
  sessionType: UserSessionType
  sessionState: UserSessionState
  sessionStatus: UserSessionStatus
}

export interface DepartmentTeamListItemDto {
  id: string
  name: string
  teams: IdNameDto[]
}

export interface IdNameDto {
  id: string
  name: string
}

export interface CarParkTenantBrandingDto {
  id: string
  clientRef: string
  name: string
  elements: BrandingElementDto[]
  support: Support
}

export interface Support {
  contact: string
  email: string
  phone: string
  url: string
}

export interface BrandingElementDto {
  name: string
  elementType: BrandingElementType
  colourValue: BrandingElementColourValueDto
  imageValue: BrandingElementImageValueDto
}

export enum BrandingElementType {
  Colour = 'Colour',
  Image = 'Image',
}

export interface BrandingElementColourValueDto {
  value: string
}

export interface BrandingElementImageValueDto {
  mimeType: string
  url: string
  width: number
  height: number
}

export type InvitationCodeResponseState = AggregateState<InvitationCodeResponse>
export type InvitationCodeResponseActionTypes = AggregateActionTypes<InvitationCodeResponse>

export const INVITATION_API_RESOURCE_PATH = '/public/operators/:operatorId/invitations'
export const SESSION_API_RESOURCE_PATH = '/public/operators/:operatorId/sessions'
