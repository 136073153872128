import React, { useEffect, useState } from 'react'
import { useQuery } from '../../hook/useQuery'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { applyActionCode, checkActionCode, getAuth } from 'firebase/auth'
import { clearNotification, setNotification } from '../../store/common/notifications/action'
import { resetLoading, setLoading } from '../../store/common/loading/action'
import { NotificationType } from '../../store/common/notifications/types'
import { selectLoading } from '../../store/common/loading/types'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import { RootState } from '../../store'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import firebase from 'firebase/compat/app'
import { fetchAggregate } from '../../common/axios-action'
import { InvitationCodeResponse, SESSION_API_RESOURCE_PATH } from '../invitation/types'
import { AGGREGATE_INVITATION } from '../../common/aggregate.types'
import { selectCurrentApiKey, selectCurrentOperatorId, selectShuffleUrl } from '../../config/app/reducers'
import {
  CHECK_PASSWORD_RESET_API_RESOURCE_PATH,
  CHECK_SMS_VERIFIED_API_RESOURCE_PATH,
  EMAIL_VERIFIED_API_RESOURCE_PATH,
  MemberSessionInfo,
  RESEND_EMAIL_VERIFICATION_API_RESOURCE_PATH,
  UserSessionState,
  UserSessionType,
} from './types'
import { useNavigate } from 'react-router'
import ResetPassword from './reset-password'
import SmsVerification from './sms-verification'
import { Error } from '../../common/types'
import Loading from '../../components/ui/Loading'
import axios from 'axios'
import { SERVER_API_KEY_HEADER } from '../../config/constants'
import EmailResendPage from './common/email-resend'

const useStyles = makeStyles((theme) => ({
  selfRegModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    minHeight: '300px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

const VerifyEmail: React.FC = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  const [firebaseInitialize, setFirebaseInitialize] = useState<boolean>(false)
  const [mode, setMode] = useState<string | null>(null)
  const [oobCode, setOobCode] = useState<string | null>(null)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [emailVerificationComplete, setEmailVerificationComplete] = useState<boolean>(false)
  const [resendEmailComplete, setResendEmailComplete] = useState<boolean>(false)
  const [resendSMSComplete, setResendSMSComplete] = useState<boolean>(false)
  const [resendPasswordComplete, setResendPasswordComplete] = useState<boolean>(false)
  const [memberSessionInfo, setMemberSessionInfo] = useState<MemberSessionInfo | null>(null)
  const currentOperatorId = useSelector(selectCurrentOperatorId)

  const apiUrl = useSelector(selectShuffleUrl)
  const apiKey = useSelector(selectCurrentApiKey)

  const [OTPValue, setOTPValue] = useState<string>('')
  const loading = useSelector(selectLoading)

  const { tenantConfig, loadingOne, errorOnUtilityData } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
      errorOnUtilityData: state.invitationReducer.error,
      loadingOne: state.invitationReducer.loadingOne,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (tenantConfig != null) {
      console.info('Applying tenant config', tenantConfig)
      const firebaseConfig = tenantConfig.firebase
      console.info('Applying firebase config', firebaseConfig)
      firebase.initializeApp(firebaseConfig)
      firebase.auth().tenantId = tenantConfig.firebaseAuth.tenantId
      setFirebaseInitialize(true)
    }
  }, [tenantConfig])

  useEffect(() => {
    if (query) {
      setOobCode(query.get('oobCode'))
      setMode(query.get('mode'))
      setSessionId(query.get('sessionId'))
    }
  }, [query])

  useEffect(() => {
    if (sessionId && currentOperatorId) {
      dispatch(
        fetchAggregate<InvitationCodeResponse>(
          AGGREGATE_INVITATION,
          SESSION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId),
          sessionId,
        ),
      )
    }
  }, [dispatch, sessionId, currentOperatorId])

  useEffect(() => {
    let errors: Error[] = []

    if (errorOnUtilityData) {
      errors = JSON.parse(JSON.stringify(errorOnUtilityData))
    }

    if (errors.length > 0) {
      let firstError: Error = errors[0]

      if (firstError.code === '400') {
        navigate('/email-expired')
      } else {
        dispatch(
          setNotification(NotificationType.ERROR, [
            firstError
              ? firstError.code === '404'
                ? 'Verification link is expired, or has already been used.'
                : firstError.message
              : 'Unexpected error, Please try again',
          ]),
        )
        navigate('/not-found')
      }
    }
  }, [errorOnUtilityData, dispatch, navigate])

  const onClickResendEmail = (showNotification?: boolean) => {
    if (sessionId && currentOperatorId) {
      axios({
        method: 'POST',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        url:
          apiUrl +
          RESEND_EMAIL_VERIFICATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':sessionId',
            sessionId,
          ),
      })
        .then((resp) => {
          if (showNotification) {
            dispatch(setNotification(NotificationType.INFO, ['Email sent successfully.']))
          }
          dispatch(resetLoading())
          setResendEmailComplete(true)
        })
        .catch((err) => {
          setResendEmailComplete(true)
          dispatch(resetLoading())
          dispatch(
            setNotification(NotificationType.ERROR, ['Something went wrong, please click on Resend email link ']),
          )
        })
    }
  }

  const handleEmailVerification = (memberSessionInfo: MemberSessionInfo) => {
    if (oobCode && mode && firebaseInitialize && memberSessionInfo && currentOperatorId && apiKey && apiUrl) {
      setEmailVerificationComplete(false)
      setResendEmailComplete(false)
      dispatch(clearNotification())
      dispatch(setLoading())
      const auth = getAuth()
      let restoredEmail: string | null | undefined = null

      checkActionCode(auth, oobCode)
        .then((resp) => {
          restoredEmail = resp['data']['email']
          if (restoredEmail) {
            applyActionCode(auth, oobCode)
              .then((resp) => {
                axios({
                  method: 'POST',
                  headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
                  url:
                    apiUrl +
                    EMAIL_VERIFIED_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
                      ':sessionId',
                      memberSessionInfo.sessionId ? memberSessionInfo.sessionId : '',
                    ),
                  data: { email: restoredEmail },
                })
                  .then((resp) => {
                    let memberSessionInfo: MemberSessionInfo = resp.data as MemberSessionInfo
                    if (memberSessionInfo) {
                      decisionMaker(memberSessionInfo)
                    }
                    setMemberSessionInfo(memberSessionInfo)
                    dispatch(resetLoading())
                  })
                  .catch((err) => {
                    dispatch(resetLoading())
                    const errors: Error[] = JSON.parse(JSON.stringify(err))
                    console.debug(errors)
                    setMemberSessionInfo(null)
                    dispatch(setNotification(NotificationType.ERROR, ['Something went wrong, please try again']))
                  })
              })
              .catch((error) => {
                dispatch(resetLoading())
                setMemberSessionInfo(null)
                dispatch(setNotification(NotificationType.ERROR, ['Something went wrong, please try again']))
              })
          } else {
            dispatch(resetLoading())
            setMemberSessionInfo(null)
            dispatch(setNotification(NotificationType.ERROR, ['Something went wrong, please try again']))
          }
        })
        .catch((error) => {
          dispatch(resetLoading())
          //check Action code failed , oob code is invalid resend email again
          onClickResendEmail()
        })
    }
  }

  const handleMobileVerification = (memberSessionInfo: MemberSessionInfo) => {
    if (memberSessionInfo && currentOperatorId && apiKey && apiUrl) {
      setEmailVerificationComplete(false)
      setResendSMSComplete(false)
      dispatch(clearNotification())
      dispatch(setLoading())
      //email is verified but not SMS
      //post checkSendMobileVerificationSMs -> success then go OTP page , other sms resend page
      //failed cases

      axios({
        method: 'POST',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        url:
          apiUrl +
          CHECK_SMS_VERIFIED_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':sessionId',
            memberSessionInfo.sessionId,
          ),
        data: {},
      })
        .then((resp) => {
          let memberSessionInfo: MemberSessionInfo = resp.data as MemberSessionInfo
          setMemberSessionInfo(memberSessionInfo)
          setResendSMSComplete(true)
          dispatch(resetLoading())
        })
        .catch((err) => {
          setResendSMSComplete(true)
          dispatch(resetLoading())
          dispatch(
            setNotification(NotificationType.ERROR, ['Something went wrong, please click on Send the code again link']),
          )
        })
    }
  }

  const handleResetPasswordVerification = (memberSessionInfo: MemberSessionInfo) => {
    if (memberSessionInfo && currentOperatorId && apiKey && apiUrl) {
      setEmailVerificationComplete(false)
      setMemberSessionInfo(null)
      dispatch(setLoading())
      //password reset
      //post checkSendPasswordResetEmail -> password reset success page
      //failed cases
      axios({
        method: 'POST',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        url:
          apiUrl +
          CHECK_PASSWORD_RESET_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId).replace(
            ':sessionId',
            memberSessionInfo.sessionId,
          ),
        data: {},
      })
        .then((resp) => {
          let memberSessionInfo: MemberSessionInfo = resp.data as MemberSessionInfo
          setMemberSessionInfo(memberSessionInfo)
          dispatch(resetLoading())
          setResendPasswordComplete(true)
        })
        .catch((err) => {
          setResendPasswordComplete(true)
          dispatch(resetLoading())
          dispatch(setNotification(NotificationType.ERROR, ['Something went wrong']))
        })
    }
  }

  const decisionMaker = (memberSessionInfo: MemberSessionInfo) => {
    switch (memberSessionInfo.sessionState) {
      case UserSessionState.EmailVerification: {
        //call to firebase checkActionCode and then updateEMailVerified
        //and also check OOB code valid , id OOB code invalid then resend email verified link //onClickResendEmail
        //failed cases
        return handleEmailVerification(memberSessionInfo)
      }
      case UserSessionState.MobileVerification: {
        //email is verified but not SMS
        //post checkSendMobileVerificationSMs -> success then go OTP page , other sms resend page
        //failed cases
        return handleMobileVerification(memberSessionInfo)
      }
      case UserSessionState.PasswordReset: {
        //password reset
        //post checkSendPasswordResetEmail -> password reset success page
        //failed cases
        if (mode === 'resetPassword') {
          return handleResetPasswordVerification(memberSessionInfo)
        } else {
          navigate('/final-complete', {
            state: { data: memberSessionInfo },
          })
          return
        }
      }
      case UserSessionState.Completed: {
        navigate('/final-complete', {
          state: { data: memberSessionInfo },
        })
      }
    }
  }

  useEffect(() => {
    if (firebaseInitialize && sessionId && currentOperatorId && apiKey && apiUrl) {
      dispatch(clearNotification())

      setMemberSessionInfo(null)
      axios({
        method: 'GET',
        headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
        url: apiUrl + SESSION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId) + '/' + sessionId,
      })
        .then((resp) => {
          let invitation: InvitationCodeResponse = resp.data as InvitationCodeResponse
          decisionMaker({
            id: null,
            sessionId: invitation.sessionId,
            sessionType: invitation.sessionType,
            sessionState: invitation.sessionState,
            sessionStatus: invitation.sessionStatus,
          })
        })
        .catch((err) => {
          setMemberSessionInfo(null)
          dispatch(resetLoading())
          dispatch(setNotification(NotificationType.ERROR, ['Something went wrong, please click on Resend Sms link ']))
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, firebaseInitialize, currentOperatorId, apiUrl, apiKey, sessionId])

  return (
    <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
      {(loading || loadingOne) && <Loading />}
      {emailVerificationComplete && memberSessionInfo && (
        <SmsVerification
          setOTPValue={setOTPValue}
          OTPValue={OTPValue}
          isMobileNumberVerification={memberSessionInfo.sessionType === UserSessionType.VerifyMobile ? true : false}
          verificationSmsId={memberSessionInfo.sessionId}
        />
      )}
      {resendSMSComplete && memberSessionInfo && (
        <SmsVerification
          setOTPValue={setOTPValue}
          OTPValue={OTPValue}
          isMobileNumberVerification={memberSessionInfo.sessionType === UserSessionType.VerifyMobile ? true : false}
          verificationSmsId={memberSessionInfo.sessionId}
        />
      )}

      {resendPasswordComplete && memberSessionInfo && (
        <ResetPassword oobCode={oobCode} verificationSessionId={memberSessionInfo.sessionId} />
      )}

      {resendEmailComplete && <EmailResendPage onClickResend={onClickResendEmail} />}
    </Box>
  )
}

export default VerifyEmail
