import { RootState } from '../../index'

export const SET_LOADING = 'SET_LOADING'
export const RESET_LOADING = 'RESET_LOADING'

export interface LoadingState {
  loading: boolean | null
}

interface SetLoadingAction {
  type: typeof SET_LOADING
}

interface ResetLoadingAction {
  type: typeof RESET_LOADING
}

export type LoadingActionTypes = SetLoadingAction | ResetLoadingAction

export const selectLoading = (state: RootState) => (state.loadingReducer.loading ? state.loadingReducer.loading : false)
