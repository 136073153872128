export interface CachedAggregates<T> {
  values: T[]
  totalCount: number | null
  link: string | null
  eTag: string | null
}

export interface CachedIdNames {
  values: IdClientRefName[]
  eTag: string | null
}

export interface IdClientRefName {
  id: string
  name: string
}

export interface ShowToolTip {
  id: number
  show: boolean
  message: string
}

export interface Error {
  code: string
  message: string
}

export interface AggregateState<T> {
  loadingList: boolean
  loadingOne: boolean
  inserting: boolean
  inserted: boolean
  updating: boolean
  updated: boolean
  lifeCycleOnly: null
  updatingLifeCycle: boolean
  updateLifeCycleSuccess: boolean
  applicationStatus: null
  updatingApplicationStatus: boolean
  updatedApplicationStatus: boolean
  aggregates: CachedAggregates<T>
  aggregate: T | null
  deleting: boolean
  deleted: boolean
  idOnly?: IdOnly
  idNames: CachedIdNames
  error: string | null
}

export interface IdOnly {
  id: string | null
  applicationStatus?: string | null
  sessionId?: string
}

export enum OrganisationType {
  CarParkTenant = 'CarParkTenant',
  Distributor = 'Distributor',
}

export interface OrganisationResource {
  organisationId: string
  organisationType: OrganisationType
}

export interface ConsumerDto {
  id: string
  email: string
  mobile: MobileDto
  providerId: string
  firstName: string
  lastName: string
  consumerType: ConsumerType
  lifecycle: Lifecycle
  acceptedTermsAndPrivacy: boolean
  sendPromoAndOffers: boolean
  version: number
  operatorId: string
  tenantId: string
  firebaseTenantId: string
  timeZoneId: string
  verificationSessionId: string
}

export interface MobileDto {
  country: 'AU'
  number: string
}

export interface MemberDto extends ConsumerDto {
  clientRef: string
  code: string
  departmentId: string
  teamId: string
  baseFacilityId: string
  facilityIds: string[]
  memberPool: MemberPool
  employeeClass: EmployeeClass
  contractEndDate: string
}

export interface SearchCriteria {
  page: number | null
  size: number | null
  sortBy: number | null
  sortDirection: number | null
  lifecycle: Lifecycle | null
}

export interface ErrorResponse {
  code: string
  message: string
}

export enum Lifecycle {
  Draft = 'Draft',
  Active = 'Active',
  Suspended = 'Suspended',
  Deleted = 'Deleted',
}

export interface LifecycleOnly {
  lifecycle: Lifecycle
}

export enum Mode {
  Add,
  Edit,
}

export interface DisableCheck {
  index: number | null
  allowEdit: boolean
}

export enum ConsumerType {
  Guest = 'Guest',
  SignedUp = 'SignedUp',
  CarParkTenantMember = 'CarParkTenantMember',
  DistributionChannelMember = 'DistributionChannelMember',
}

export enum MemberPool {
  Staff_Executive = 'Staff_Executive',
  Staff_Management = 'Staff_Management',
  Staff_Office = 'Staff_Office',
  Staff_Mobile = 'Staff_Mobile',
}

export enum EmployeeClass {
  Permanent = 'Permanent',
  Contract = 'Contract',
}

export const employeeClassIdNames: IdClientRefName[] = [
  {
    id: 'Permanent',
    name: 'Permanent',
  },
  {
    id: 'Contract',
    name: 'Contract',
  },
]

export const memberPoolsIdNames: IdClientRefName[] = [
  {
    id: 'Staff_Executive',
    name: 'Executive',
  },
  {
    id: 'Staff_Management',
    name: 'Management',
  },
  {
    id: 'Staff_Office',
    name: 'Office',
  },
  {
    id: 'Staff_Mobile',
    name: 'Mobile',
  },
]

export enum SalesChannelType {
  Pay = 'Pay',
  Res = 'Res',
  Charge = 'Charge',
  Lease = 'Lease',
  Valido = 'Valido',
  Pass = 'Pass',
}

export enum UserClass {
  Admin = 'Admin',
  Core = 'Core',
  Consumer = 'Consumer',
  None = 'None',
}

export interface UserRef {
  id: string
  userClass: UserClass
}

export interface MemberVehicle {
  memberVehicleId: string
  plate: string
  makeCode: string | null
}

export enum PurchaseType {
  PrePay = 'PrePay',
  PostPay = 'PostPay',
}

export enum OrderStatus {
  PrePay = 'PrePay',
  PostPay = 'PostPay',
}

export interface Revision {
  id: string
  code: string
  clientRef: string
  version: number
}

export enum ReservationType {
  FacilityReserved = 'FacilityReserved',
  FacilityUnReserved = 'FacilityUnReserved',
  ZonedReserved = 'ZonedReserved',
  ZonedUnReserved = 'ZonedUnReserved',
}

export interface AgreementReservation {
  reservationType: ReservationType
  facilityZoneId: string | null
  facilityZoneName: string | null
  bayNo: string | null
  layoutId: string | null
  layoutName: string | null
  allocationId: string | null
  allocationName: string | null
}

export interface SingleStay {
  startInstant: string
  finishInstant: string
  startDateTime: string
  finishDateTime: string
  durationMinutes: number
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface WeeklySlot {
  dayOfWeek: DayOfWeek
  dayOfWeekOrdinal: number
  fromMinutes: number | null
  toMinutes: number | null
  enable: boolean
}

export interface WeeklyStay {
  startInstant: string
  finishInstant: string
  startDateTime: string
  finishDateTime: string
  slots: WeeklySlot[]
}

export interface PermanentStay {
  startInstant: string
  finishInstant: string
  startDateTime: string
  finishDateTime: string
}

export enum AgreementType {
  Single = 'Single',
  Weekly = 'Weekly',
  Permanent = 'Permanent',
  Daily = 'Daily',
  Charge = 'Charge',
}

export enum AgreementStatus {
  /**
   * Valid agreement. Valid agreement. For long term multiple stay
   * agreements, the status remains Valid until the entire agreement
   * expires.
   */
  Valid = 'Valid',

  /**
   * Single use agreement has been consumed
   */
  Completed = 'Completed',

  /**
   * Agreement end date has been exceeded. The end date of the
   * agreement should be checked in realtime if the expiry is time
   * critical.
   */
  Expired = 'Expired',

  /**
   * Single use agreement has expired and was not consumed.
   */
  NoShow = 'NoShow',

  /**
   * Agreement has been cancelled.
   */
  Cancelled = 'Cancelled',
}

export enum EntryStatus {
  /**
   * The member is in the facility.
   */
  OnSite = 'OnSite',

  /**
   * The member is not in the facility.
   */
  OffSite = 'OffSite',
}

export interface MemberParkingStayAgreement {
  id: string
  facilityId: string
  facilityName: string
  reservations: AgreementReservation[]
  charge: number | null
  unitPrice: number | null
  quantity: number
  timeZoneId: string
  agreementType: AgreementType
  weeklyStay: WeeklyStay | null
  singleStay: SingleStay | null
  permanentStay: PermanentStay | null
  agreementStatus: AgreementStatus
  entryStatus: EntryStatus
}
