import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import unavailableIcon from '../../assets/svg/ws-b2b-icon-no_bays.svg'
import { useSelector } from 'react-redux'
import { selectCompanyName } from '../invitation/reducer'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#979797',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
  },
  selfRegModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
  },
}))

interface IProps {}
const InvalidCode: React.FC<IProps> = (props) => {
  const classes = useStyles()

  const organisationName: string | null = useSelector(selectCompanyName)

  return (
    <>
      <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
        <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} className="justified-content">
              <img className="self-reg-icon self-reg-icon-unavailable" src={unavailableIcon} alt="Unavailable" />
              <h2 className="self-reg-h2 centered-h2">Parking isn’t available.</h2>
              <p className="self-reg-sub-text">
                Sorry, but you’re unable to register with {organisationName}. Please contact your company administrator
                if you need help.{' '}
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default InvalidCode
