import { AggregateState } from '../../common/types'
import { AggregateActionTypes } from '../../common/aggregate.types'

export interface EmailOnly {
  email: string | null | undefined
}

export interface MemberSessionInfo {
  id: string | null
  sessionId: string
  sessionType: UserSessionType
  sessionState: UserSessionState
  sessionStatus: UserSessionStatus
}

export enum UserSessionType {
  OnBoarding = 'OnBoarding',
  VerifyMobile = 'VerifyMobile',
  PasswordReset = 'PasswordReset',
}

export enum UserSessionState {
  EmailVerification = 'EmailVerification',
  MobileVerification = 'MobileVerification',
  PasswordReset = 'PasswordReset',
  Completed = 'Completed',
}

export enum UserSessionStatus {
  Init = 'Init',
  Queued = 'Queued',
  Success = 'Success',
  Failed = 'Failed',
}

export type VerifyEmailResponseState = AggregateState<MemberSessionInfo>
export type VerifyEmailResponseActionTypes = AggregateActionTypes<MemberSessionInfo>

export const EMAIL_VERIFIED_API_RESOURCE_PATH = '/public/operators/:operatorId/sessions/:sessionId:updateEmailVerified'

export const RESEND_EMAIL_VERIFICATION_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:resendVerificationEmail'

export const SMS_VERIFIED_API_RESOURCE_PATH = '/public/operators/:operatorId/sessions/:sessionId:updateMobileVerified'
export const CHECK_SMS_VERIFIED_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:checkSendMobileVerificationSms'

export const CHECK_PASSWORD_RESET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:checkSendPasswordResetEmail'

export const RESEND_PASSWORD_RESET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:resendPasswordResetEmail'

export const RESEND_SMS_VERIFICATION_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:resendMobileVerificationSms'

export const UPDATE_PASSWORD_RESET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:updatePasswordReset'
