import { ErrorState, SET_ERROR, RESET_ERROR, ErrorActionTypes } from './types'

const initialState: ErrorState = {
  error: null,
}

export function errorReducer(state = initialState, action: ErrorActionTypes): ErrorState {
  switch (action.type) {
    case SET_ERROR: {
      return {
        error: action.payload.error,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    default:
      return state
  }
}
