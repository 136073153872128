import { CLEAR_NOTIFICATION, NotificationActionTypes, NotificationState, SET_NOTIFICATION } from './types'

const initialState: NotificationState = {
  type: null,
  messages: null,
}

export function notificationsReducer(state = initialState, action: NotificationActionTypes): NotificationState {
  switch (action.type) {
    case SET_NOTIFICATION: {
      return {
        ...state,
        type: action.payload.type,
        messages: action.payload.messages,
      }
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        type: null,
        messages: null,
      }
    }
    default:
      return state
  }
}
