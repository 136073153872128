import { AggregateState, MemberPool, MobileDto, OrganisationType } from '../../../common/types'
import { AggregateActionTypes } from '../../../common/aggregate.types'

export interface MemberApplication {
  id: string | null
  code: string | null
  email: string | null
  mobileNo: string | null
  mobile: MobileDto | null
  firstName: string | null
  lastName: string | null
  memberId: string | null
  departmentId: string | null
  teamId: string | null
  baseFacilityId: string | null
  memberPool: MemberPool | null
  employeeClass: string | null
  contractEndDate: string | null
  organisationType: OrganisationType | null
  operatorId: string | null
  pendingInstant: string | null
  processedInstant: string | null
  declinedReason: string | null
  invitationId: string | null
  tenantId: string | null
  carParkTenantId: string | null
}

export type MemberApplicationState = AggregateState<MemberApplication>
export type MemberApplicationActionTypes = AggregateActionTypes<MemberApplication>

export const MEMBER_CP_API_RESOURCE_PATH =
  '/public/operators/:operatorId/invitations/:invitationId/carpark-tenants/:carParkTenantId/member-applications'
export const MEMBER_DC_API_RESOURCE_PATH =
  '/public/operators/:operatorId/invitations/:invitationId/distribution-channels/:distributionChannelId/member-applications'
