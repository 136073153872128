import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectCurrentOperatorId, selectCurrentTenantId } from '../../config/app/reducers'
import { RootState } from '../../store'
import { fetchAggregate, insertAggregate } from '../../common/axios-action'
import { AGGREGATE_INVITATION, AGGREGATE_MEMBER_APPLICATION } from '../../common/aggregate.types'
import { DepartmentTeamListItemDto, INVITATION_API_RESOURCE_PATH, InvitationCodeResponse } from './types'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router'
import {
  MEMBER_CP_API_RESOURCE_PATH,
  MEMBER_DC_API_RESOURCE_PATH,
  MemberApplication,
} from '../../store/common/member-application/types'
import { EmployeeClass, ErrorResponse, IdClientRefName, memberPoolsIdNames, OrganisationType } from '../../common/types'
import { FormHelperText } from '@mui/material'
import { setNotification } from '../../store/common/notifications/action'
import { NotificationType } from '../../store/common/notifications/types'
import EmailVerification from '../email-verification'
import Loading from '../../components/ui/Loading'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shuffleButton: {
    fontFamily: 'Untitled Sans',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '1rem',
    width: '100%',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '7px 22px',
    zIndex: 5000,
  },
  selfRegModal: {
    background: 'white',
    padding: '40px',
    borderRadius: '4px',
    marginTop: '185px',
    marginBottom: '35px',
    width: '705px',
    textAlign: 'left',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
    zIndex: 3,
  },
}))

interface IProps {}
const Invite: React.FC<IProps> = (props) => {
  const classes = useStyles()

  let { invitationCode } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentOperatorId = useSelector(selectCurrentOperatorId)
  const currentTenantId = useSelector(selectCurrentTenantId)

  const [displayInvitationForm, setDisplayInvitationForm] = useState<boolean>(true)

  const [departmentIdNames, setDepartmentIdNames] = useState<IdClientRefName[]>([])
  const [teamIdNames, setTeamIdNames] = useState<IdClientRefName[]>([])
  const [facilityIdNames, setFacilityIdNames] = useState<IdClientRefName[]>([])
  const [memberApplicationDto, setMemberApplicationDto] = useState<MemberApplication>({
    id: null,
    code: null,
    email: null,
    mobileNo: null,
    mobile: null,
    firstName: null,
    lastName: null,
    memberId: null,
    departmentId: null,
    teamId: null,
    baseFacilityId: null,
    memberPool: null,
    employeeClass: null,
    contractEndDate: null,
    organisationType: null,
    operatorId: null,
    pendingInstant: null,
    processedInstant: null,
    declinedReason: null,
    invitationId: null,
    tenantId: null,
    carParkTenantId: null,
  })

  const [firstNameError, setFirstNameError] = useState<string>('')
  const [lastNameError, setLastNameError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [emailSubmitError, setEmailSubmitError] = useState<string>('')
  const [mobilePhoneError, setMobilePhoneError] = useState<string>('')
  const [memberPoolError, setMemberPoolError] = useState<string>('')
  const [officeLocationError, setOfficeLocationError] = useState<string>('')
  const [departmentError, setDepartmentError] = useState<string>('')
  const [teamError, setTeamError] = useState<string>('')

  const { error, invitation, loadingOne, inserting, inserted, idOnly, saveError } = useSelector(
    (state: RootState) => ({
      error: state.invitationReducer.error,
      invitation: state.invitationReducer.aggregate,
      loadingOne: state.invitationReducer.loadingOne,
      inserted: state.memberApplicationReducer.inserted,
      inserting: state.memberApplicationReducer.inserting,
      idOnly: state.memberApplicationReducer.idOnly,
      saveError: state.memberApplicationReducer.error,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (invitationCode && currentOperatorId) {
      dispatch(
        fetchAggregate<InvitationCodeResponse>(
          AGGREGATE_INVITATION,
          INVITATION_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId),
          invitationCode,
        ),
      )
    }
  }, [dispatch, invitationCode, currentOperatorId])

  useEffect(() => {
    if (idOnly && inserted) {
      setDisplayInvitationForm(false)
    }
  }, [inserted, idOnly, navigate])

  useEffect(() => {
    if (error) {
      dispatch(
        setNotification(NotificationType.ERROR, [
          'You’re unable to register for parking. Please contact your company administrator',
        ]),
      )
    }
  }, [error, navigate, dispatch])

  useEffect(() => {
    if (saveError) {
      const errors: ErrorResponse[] = JSON.parse(JSON.stringify(saveError))
      if (errors.length > 0) {
        let firstError: ErrorResponse = errors[0]
        if (firstError.code === 'email') {
          dispatch(
            setNotification(NotificationType.ERROR, [
              'You’re unable to register for parking. Please contact your company administrator',
            ]),
          )
        } else if (firstError.code.includes('EMAIL_EXISTS')) {
          dispatch(setNotification(NotificationType.ERROR, ['Email already exists. Please use different address']))
        } else {
          dispatch(
            setNotification(NotificationType.ERROR, [
              firstError.message ? firstError.message : firstError.code.substring(0, 41),
            ]),
          )
        }
      }
    }
  }, [saveError, dispatch, navigate])

  useEffect(() => {
    let departments: IdClientRefName[] = []
    if (invitation) {
      invitation.departmentTeams?.map((row) => {
        return departments.push({
          id: row.id,
          name: row.name,
        })
      })
      setDepartmentIdNames(departments)

      if (departments && departments.length === 1) {
        let name = 'departmentId'
        setMemberApplicationDto({ ...memberApplicationDto, [name]: departments[0].id })
      }

      if (invitation.facilities) {
        setFacilityIdNames(invitation.facilities)
      } else {
        setFacilityIdNames([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation])

  useEffect(() => {
    if (memberApplicationDto?.departmentId && invitation) {
      let department: DepartmentTeamListItemDto | undefined = invitation.departmentTeams?.find(
        (row) => row.id === memberApplicationDto?.departmentId,
      )
      if (department) {
        setTeamIdNames(department?.teams)
        setMemberApplicationDto({ ...memberApplicationDto, teamId: null })
      } else {
        setTeamIdNames([])
      }
    } else {
      setTeamIdNames([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberApplicationDto.departmentId, invitation])

  const onClickSubmit = (event: any) => {
    event.preventDefault()
    resetForm()

    let isValid: boolean = true
    if (!memberApplicationDto?.firstName) {
      setFirstNameError('Required')
      isValid = false
    }
    if (!memberApplicationDto?.lastName) {
      setLastNameError('Required')
      isValid = false
    }
    if (!memberApplicationDto?.email) {
      setEmailError('Required')
      isValid = false
    } else {
      if (memberApplicationDto?.email) {
        let emailInValid = isValidEmail(memberApplicationDto?.email)
        if (!emailInValid) {
          setEmailError('Email is not wel-formed')
          isValid = false
        }
      }
    }
    if (!memberApplicationDto?.memberPool) {
      setMemberPoolError('Required')
      isValid = false
    }
    if (!memberApplicationDto?.baseFacilityId) {
      setOfficeLocationError('Required')
      isValid = false
    }
    if (!memberApplicationDto?.mobileNo) {
      setMobilePhoneError('Required')
      isValid = false
    }

    if (!memberApplicationDto?.departmentId) {
      setDepartmentError('Required')
      isValid = false
    }

    if (isValid && memberApplicationDto && currentOperatorId && invitation && currentTenantId && invitationCode) {
      memberApplicationDto.operatorId = currentOperatorId
      memberApplicationDto.organisationType = invitation.organisationType
      memberApplicationDto.tenantId = currentTenantId
      memberApplicationDto.carParkTenantId = invitation.organisationId
      memberApplicationDto.employeeClass = EmployeeClass.Permanent
      memberApplicationDto.mobile = {
        number: memberApplicationDto.mobileNo ? memberApplicationDto.mobileNo : '',
        country: 'AU',
      }
      memberApplicationDto.teamId = memberApplicationDto.teamId ? memberApplicationDto.teamId : null
      resetForm()

      if (invitation.organisationType === OrganisationType.CarParkTenant) {
        dispatch(
          insertAggregate<MemberApplication>(
            AGGREGATE_MEMBER_APPLICATION,
            MEMBER_CP_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
              .replace(':invitationId', invitationCode)
              .replace(':carParkTenantId', invitation.organisationId ? invitation.organisationId : ''),
            memberApplicationDto,
          ),
        )
      } else if (invitation.organisationType === OrganisationType.Distributor) {
        dispatch(
          insertAggregate<MemberApplication>(
            AGGREGATE_MEMBER_APPLICATION,
            MEMBER_DC_API_RESOURCE_PATH.replace(':operatorId', currentOperatorId)
              .replace(':invitationId', invitationCode)
              .replace(':distributionChannelId', invitation.organisationId ? invitation.organisationId : ''),
            memberApplicationDto,
          ),
        )
      } else {
        return
      }
    } else {
      return
    }
  }

  const resetForm = () => {
    setFirstNameError('')
    setLastNameError('')
    setEmailError('')
    setEmailSubmitError('')
    setMemberPoolError('')
    setOfficeLocationError('')
    setMobilePhoneError('')
    setDepartmentError('')
    setTeamError('')
  }

  const isValidEmail = (value: string) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(value) ? false : true)

  const onChangeField = (event: any) => {
    let { name, value } = event.target
    setMemberApplicationDto({ ...memberApplicationDto, [name]: value })
    if (name === 'firstName') {
      setFirstNameError('')
    } else if (name === 'email') {
      setEmailSubmitError('')
      setEmailError('')
    } else if (name === 'lastName') {
      setLastNameError('')
    } else if (name === 'mobileNo') {
      setMobilePhoneError('')
    }
  }

  const handleChangeSelect = (event: any) => {
    let { name, value } = event.target
    setMemberApplicationDto({ ...memberApplicationDto, [name]: value })
    if (name === 'memberPool') {
      setMemberPoolError('')
    } else if (name === 'baseFacilityId') {
      setOfficeLocationError('')
    } else if (name === 'departmentId') {
      setDepartmentError('')
    } else if (name === 'teamId') {
      setTeamError('')
    }
  }

  return (
    <>
      {displayInvitationForm && (
        <Box className={classes.selfRegModal} id="self-reg-mobile-wrapper">
          {(loadingOne || inserting) && <Loading />}

          <Container maxWidth="lg" disableGutters={true} className="self-reg-modal-styles base-button">
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <h2 className="self-reg-h2">Register for parking at {invitation?.organisationName}</h2>
                <p className="self-reg-required-text">*All fields required</p>
              </Grid>
              <Grid item xs={12}>
                <h3 className="self-reg-h3">About you</h3>
              </Grid>
              <Grid item xs={12} md={6}>
                <p className="input-label">First name</p>
                <TextField
                  name={'firstName'}
                  id="firstName"
                  InputProps={firstNameError ? { className: 'shuffleInputError' } : { className: 'shuffleInput' }}
                  defaultValue=""
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={memberApplicationDto?.firstName}
                  helperText={firstNameError ? 'Please enter your first name.' : ' '}
                  onChange={onChangeField}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="input-label">Surname</p>
                <TextField
                  id="lastName"
                  name={'lastName'}
                  InputProps={lastNameError ? { className: 'shuffleInputError' } : { className: 'shuffleInput' }}
                  defaultValue=""
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={memberApplicationDto?.lastName}
                  helperText={lastNameError ? 'Please enter your surname.' : ' '}
                  onChange={onChangeField}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="input-label">Email address</p>
                <TextField
                  id="email"
                  name={'email'}
                  InputProps={
                    emailError || emailSubmitError ? { className: 'shuffleInputError' } : { className: 'shuffleInput' }
                  }
                  defaultValue=""
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  margin="dense"
                  value={memberApplicationDto?.email}
                  helperText={
                    emailError ? 'Please enter a valid email address.' : emailSubmitError ? emailSubmitError : ' '
                  }
                  fullWidth
                  onChange={onChangeField}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="input-label">Mobile phone number</p>
                <TextField
                  id="mobileNo"
                  name={'mobileNo'}
                  InputProps={mobilePhoneError ? { className: 'shuffleInputError' } : { className: 'shuffleInput' }}
                  defaultValue=""
                  InputLabelProps={{ className: 'shuffleLabel' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={memberApplicationDto?.mobileNo}
                  helperText={mobilePhoneError ? 'Please enter a valid mobile phone number.' : ' '}
                  onChange={onChangeField}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className="select-heading">Role</InputLabel>
                <Select
                  className={memberPoolError ? 'shuffle-select select-error' : 'shuffle-select'}
                  id="memberPool"
                  name={'memberPool'}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={memberApplicationDto?.memberPool}
                  onChange={handleChangeSelect}
                >
                  {memberPoolsIdNames.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {memberPoolError && <FormHelperText>Please choose your role from the list.</FormHelperText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className="select-heading">Office location</InputLabel>
                <Select
                  id="baseFacilityId"
                  name={'baseFacilityId'}
                  className={officeLocationError ? 'shuffle-select select-error' : 'shuffle-select'}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={memberApplicationDto?.baseFacilityId}
                  onChange={handleChangeSelect}
                >
                  {facilityIdNames.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {officeLocationError && (
                  <FormHelperText>Please choose your office location from the list.</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <h3 className="self-reg-h3">Your team</h3>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel className="select-heading">Choose your department</InputLabel>
                <Select
                  id="departmentId"
                  name={'departmentId'}
                  className={departmentError ? 'shuffle-select select-error' : 'shuffle-select'}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={memberApplicationDto?.departmentId}
                  onChange={handleChangeSelect}
                >
                  {departmentIdNames.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {departmentError && <FormHelperText>Please choose your department from the list.</FormHelperText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <>
                  <InputLabel className="select-heading">Choose your team</InputLabel>
                  <Select
                    id="teamId"
                    name={'teamId'}
                    className={teamError ? 'shuffle-select select-error' : 'shuffle-select'}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={memberApplicationDto?.teamId}
                    onChange={handleChangeSelect}
                    disabled={!memberApplicationDto?.departmentId}
                    displayEmpty
                  >
                    <MenuItem value="">No Team</MenuItem>
                    {teamIdNames.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {teamError && <FormHelperText>Please choose your team from the list.</FormHelperText>}
                </>
              </Grid>
              <Grid item xs={9} style={{ paddingTop: '60px' }}></Grid>
              <Grid item xs={3} style={{ paddingTop: '60px' }}>
                <Button
                  className={classes.shuffleButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={(event) => onClickSubmit(event)}
                >
                  Register Now
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}

      {!displayInvitationForm && idOnly && <EmailVerification />}
    </>
  )
}

export default Invite
